import React from 'react';
import profile from '@/assets/placeholder/profile.png';
const { REACT_APP_API_URL } = process.env;

export const baseUrl = REACT_APP_API_URL?.split('v1')[0] ?? '';

export const Image = (props: any) => {
  return (
    <img
      {...props}
      src={props.path ? baseUrl + props.path : props.src ? props.src : profile}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = profile;
      }}
    />
  );
};

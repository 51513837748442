import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Table, TableColumn, ConfirmationDialog } from '@/components/Elements';
import { Template } from '@/types';
import { useAuth } from '@/lib/auth';

const ContractTemplates = () => {
  const { user } = useAuth();
  const newData = [
    {
      id: '1',
      sr: 1,
      template: 'Sales Agreement',
      pages: '34'
    }
  ]
  const navigate = useNavigate();


  const columns: TableColumn<Template>[] = [
    {
      title: 'Template',
      field: 'template',
    },
    {
      title: 'Pages',
      field: 'pages',
    },
    {
      title: 'Actions',
      field: 'action',
      Cell({ entry }) {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              // className="btn btn-outline-dark icon"
              variant='outline'
              title="view"
              onClick={() => navigate(`/contract`)}
              style={{marginRight: "15px"}}
            >
              <div>
                <span className="mx-2">
                  <i className="fa-regular fa-eye"></i>
                </span>
              </div>
            </Button>
            <Button
              // className="btn btn-outline-dark icon"
              title="edit"
              variant='outline'
              onClick={() => navigate(`/edit-admin-contract`)}
            >
              <div>
                <span className="mx-2">
                  <i className="fa-solid fa-pen-to-square"></i>
                </span>
              </div>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="container my-4">
        <div className="main-dashboard">
          <div className="headings">
            <h3>Contract Templates</h3>
            {user?.role === 'admin' ? <p>You can view all Templates here</p> : <p>Choose a template to work with</p>}
          </div>
        </div>
        <div className="table-structure" style={{ marginTop: '90px' }}>
          <Table data={newData} columns={columns} />
        </div>
      </div>

    </>
  );
};

export default ContractTemplates;

import { Button, Spinner } from '@/components/Elements'
import { Image, baseUrl } from '@/components/Elements/Image/Image'
import { Form, InputField, TextAreaField } from '@/components/Form'
import { ContentLayout } from '@/components/Layout'
import * as z from 'zod';
import { EditTemplate } from './types/adminTypes';
import { postTemplateValues, uploadLogo } from '../api/postEditedTemplate';
import { useEffect, useState } from 'react';
import { useNotificationStore } from '@/stores/notifications';
import { useAuth } from '@/lib/auth';
import { useNavigate } from 'react-router-dom';
import { useTemplate } from '../api/getTemplate';

const schema = z.object({
    vendor_details: z.string().min(1, "Please enter vendor details"),
    company_details: z.string().min(1, "Please enter company details"),
    aggrement_desc_1: z.string().min(1, "Please enter agreement description"),
    aggrement_desc_2: z.string().min(1, "Please enter defintion and interpretation I"),
    aggrement_desc_3: z.string().min(1, "Please enter defintion and interpretation II"),
    completion_date: z.string().min(1, "Please enter completion date details"),
    land_details: z.string().min(1, "Please enter land details"),
    phase_developmnt: z.string().min(1, "Please enter phase 1 development details"),
    development_plan: z.string().min(1, "Please enter phase development plan"),
    land_plan: z.string().min(1, "Please enter phase 1 land details"),
    property_desc: z.string().min(1, "Please enter property description"),
    property_name: z.string().min(1, "Please enter  property name"),
    vendor_advocate: z.string().min(1, "Please enter vendor advocate details"),
    address: z.string().min(1, "Please enter vendor address"),
    city: z.string().min(1, "Please enter vendor city"),
    name_address: z.string().min(1, "Please enter name "),
    email: z.string().min(1, "Please enter email").email("Enter valid email"),
    po_box_no: z.string()
        .min(1, 'Please enter P.O Box no.')
        .regex(/^\d+$/)
        .transform(Number),
    car_park_space: z.string().min(1, "Please enter car park space"),
})

const EditAdminContract = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [profilePicture, setPicturePreview] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false);
    const template = useTemplate();
    const tempData = template?.data;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setPicturePreview(URL.createObjectURL(e.target.files[0]));
            setFile(e.target.files[0]);
        }
    };

    useEffect(() => {
        setPicturePreview(`${baseUrl}/${tempData?.imageUrl ?? ''}`);
    }, [tempData])

    useEffect(() => {
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 2000);
        
    }, [])

    return (
        <>
            <ContentLayout title='Edit Template'>
                <div
                    style={{
                        top: 0,
                        paddingTop: 12,
                        paddingBottom: 1,
                        backgroundColor: '#F3F4F6',
                        zIndex: 100,
                    }}
                >
                    {reload && <Spinner />}
                    {!reload &&
                        <div>
                            <Form<EditTemplate, typeof schema>
                                schema={schema}
                                onSubmit={async (values) => {
                                    setLoading(true)
                                    if(file !== null) {
                                        await uploadLogo({ templateLogo: file })
                                    }
                                    
                                    const res = await postTemplateValues(values)
                                    if (res) {
                                        setLoading(false)
                                        useNotificationStore.getState().addNotification({
                                            type: 'success',
                                            title: 'Success',
                                            message: 'Template edited successfuly',
                                        });
                                        navigate('/contract-templates')
                                    }


                                }}
                                options={{
                                    defaultValues: {
                                        vendor_details: tempData?.vendor_details ? tempData?.vendor_details : "",
                                        company_details: tempData?.company_details ? tempData?.company_details : "",
                                        aggrement_desc_1: tempData?.aggrement_desc_1 ? tempData?.aggrement_desc_1 : "",
                                        aggrement_desc_2: tempData?.aggrement_desc_2 ? tempData?.aggrement_desc_2 : "",
                                        aggrement_desc_3: tempData?.aggrement_desc_3 ? tempData?.aggrement_desc_3 : "",
                                        completion_date: tempData?.completion_date ? tempData?.completion_date : "",
                                        land_details: tempData?.land_details ? tempData?.land_details : "",
                                        phase_developmnt: tempData?.phase_developmnt ? tempData?.phase_developmnt : "",
                                        development_plan: tempData?.development_plan ? tempData?.development_plan : "",
                                        land_plan: tempData?.land_plan ? tempData?.land_plan : "",
                                        property_desc: tempData?.property_desc ? tempData?.property_desc : "",
                                        property_name: tempData?.property_name ? tempData?.property_name : "",
                                        vendor_advocate: tempData?.vendor_advocate ? tempData?.vendor_advocate : "",
                                        address: tempData?.address ? tempData?.address : "",
                                        city: tempData?.city ? tempData?.city : "",
                                        name_address: tempData?.name_address ? tempData?.name_address : "",
                                        email: tempData?.email ? tempData?.email : "",
                                        po_box_no: tempData?.po_box_no ? tempData?.po_box_no.toString() : "",
                                        car_park_space: tempData?.car_park_space ? tempData?.car_park_space : "",
                                    }
                                }}

                            >
                                {({ register, formState }) => (

                                    <>
                                        <div className="d-flex flex-row">
                                            <div style={{ position: "absolute", right: "26px", top: "50px" }}>
                                                <Button
                                                    type='submit'
                                                    variant='primary'
                                                    isLoading={loading}
                                                >
                                                    Save Template
                                                </Button>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <div className="bg-white mt-8 mb-8 py-8 px-8 shadow sm:rounded-lg sm:px-12 w-full  h-full">
                                                <form >
                                                    <Image
                                                        src={profilePicture}
                                                        alt="logo"
                                                        className="rounded-full object-cover"
                                                        style={{ height: "130px", width: "130px" }}
                                                    />
                                                    <span className="warning text-red-600 text-sm">*please add a square image</span>
                                                    <div className="row mt-4">
                                                        <div className="col-6">
                                                            <input
                                                                type='file'
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <InputField
                                                            type='text'
                                                            label='Vendor Name'
                                                            error={formState.errors['address']}
                                                            registration={register('address')}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <InputField
                                                            type='text'
                                                            label='City'
                                                            error={formState.errors['city']}
                                                            registration={register('city')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <InputField
                                                            type='text'
                                                            label='Name of Address'
                                                            error={formState.errors['name_address']}
                                                            registration={register('name_address')}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <InputField
                                                            type='text'
                                                            label='Email'
                                                            error={formState.errors['email']}
                                                            registration={register('email')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-6">
                                                        <InputField
                                                            type='number'
                                                            label='P.O Box No.'
                                                            error={formState.errors['po_box_no']}
                                                            registration={register('po_box_no')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Vendor Details'
                                                            placeholder='Anjarwalla & Khanna LLP, ALN House, Eldama Ravine Close, Off Eldama Ravine Road, Westlands, P.O. Box 200-00606 Sarit Centre, Nairobi'
                                                            registration={register('vendor_details')}
                                                            error={formState.errors['vendor_details']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Company Details'
                                                            placeholder='(Company Number
                                                            PVT-9XUJ37Z), a private company incorporated with limited liability in the
                                                            Republic of Kenya for the purposes hereof of Post Office Box Number 43233-00100,
                                                            General Mathenge Drive, Westlands, Nairobi, Kenya (hereinafter called the Vendor
                                                            which expression shall, where the context so requires, include the Vendor successors in title and assigns)'
                                                            registration={register('company_details')}
                                                            error={formState.errors['company_details']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Agreement Description'
                                                            placeholder=' Kasarani Investment Holdings Limited (company Number C.127008) a limited liability
                                                        company incorporated in Mauritius KIHL is the registered owner of the Land'
                                                            registration={register('aggrement_desc_1')}
                                                            error={formState.errors['aggrement_desc_1']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Definition & Interpretation I.'
                                                            placeholder='Agreed Rate of Interest  means the per annum rate
                                                        which is seven percent (7%) above the Central Bank Rate stipulated by the
                                                        Monetary Policy Committee of the Central Bank of Kenya (CBK) as may be published from time to time '
                                                            registration={register('aggrement_desc_2')}
                                                            error={formState.errors['aggrement_desc_2']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Definition & Interpretation II.'
                                                            placeholder='Apartments  means an aggregate of approximately six
                                                        hundred and sixty three (663) residential apartments (or such other number to be
                                                        determined by the Vendor in its sole discretion) proposed to be constructed in
                                                        the Residential Development (including the Phase 1 Development) constructed in
                                                        the Residential Development (including the Phase 1 Development) and the
                                                        expression'
                                                            registration={register('aggrement_desc_3')}
                                                            error={formState.errors['aggrement_desc_3']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Completion Date'
                                                            placeholder='Completion Date means 25 December 2022'
                                                            registration={register('completion_date')}
                                                            error={formState.errors['completion_date']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Land Details'
                                                            placeholder='parcel of land, situate in the City of
                                                        Nairobi in the Nairobi Area of the Republic of Kenya containing by measurement
                                                        five decimal nine two six (5.926) hectares or thereabouts and being the property
                                                        known as Land Reference Number 10119/7 (original Number 10119/6/2) which said
                                                        piece of land with the dimensions abuttals and boundaries thereof is delineated
                                                        on Land Survey Plan Number 419577 deposited in the Survey Records Office at
                                                        Nairobi aforesaid held for a term of ninety nine (99) years from 1 July 2017
                                                        subject however to the prevailing laws and regulations, the special conditions,
                                                        encumbrances and other matters specified in the Memorandum endorsed on the
                                                        Certificate of Title registered as Number I.R. 210045/1;'
                                                            registration={register('land_details')}
                                                            error={formState.errors['land_details']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Phase 1 Development'
                                                            placeholder='Phase 1 Development  means two hundred and twenty-one (221) Apartments (constructed on the Phase 1 Land together with
                                                        related amenities and facilities as more particularly identified in the Approved
                                                        Documents;'
                                                            registration={register('phase_developmnt')}
                                                            error={formState.errors['phase_developmnt']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Phase 1 Development Plan'
                                                            placeholder='Phase 1 Development Plan  means the plan(s) annexed
                                                        hereto in Schedule 5 and marked “Phase 1 Development”;'
                                                            registration={register('development_plan')}
                                                            error={formState.errors['development_plan']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Phase 1 Land'
                                                            placeholder='Phase 1 Land  means that portion of the Property
                                                        containing by measurement approximately one decimal eight nine (1.89) acres or
                                                        thereabouts with the dimensions, abuttals and boundaries thereof more
                                                        particularly delineated and described on the Property Plan;'
                                                            registration={register('land_plan')}
                                                            error={formState.errors['land_plan']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Property Description'
                                                            placeholder='Property  means that portion of the Land
                                                        containing by measurement approximately four decimal four nine (4.49) acres or
                                                        thereabouts with the dimensions, abuttals and boundaries thereof more
                                                        particularly delineated and described on the Property Plan;'
                                                            registration={register('property_desc')}
                                                            error={formState.errors['property_desc']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Property Name'
                                                            placeholder='“Mi Vida Garden City”'
                                                            registration={register('property_name')}
                                                            error={formState.errors['property_name']}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            placeholder='Vendor Advocates means Anjarwalla & Khanna LLP
                                                        of ALN House, Eldama Ravine Close, Off Eldama Ravine Road, Westlands P.O. Box
                                                        200-00606, Sarit Centre, Nairobi.'
                                                            label='Vendor Advocate'
                                                            registration={register('vendor_advocate')}
                                                            error={formState.errors['vendor_advocate']}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <TextAreaField
                                                            label='Car Park Space details'
                                                            placeholder='Number of car park space: One (1).'
                                                            registration={register('car_park_space')}
                                                            error={formState.errors['car_park_space']}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )}
                            </Form>

                        </div>
                    }
                </div>

            </ContentLayout>
        </>
    )
}

export default EditAdminContract
import { useNavigate } from 'react-router-dom';
// import padlock from '@/assets/padlock.svg';
import { Button, Link } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import * as z from 'zod';
import { useState } from 'react';
// import { sendForgetPasswordLink } from '../api/forgetPassword';
import { useNotificationStore } from '@/stores/notifications';
import { sendForgetPasswordLink } from '../api/postForgetLink';

const schema = z.object({
  email: z.string().min(1, 'Please enter email address').email(),
});

type ForgetPasswordValues = {
  email: string;
};

export const ForgetPasswordForm = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: ForgetPasswordValues) => {
    setLoading(true);
    try {
      const payload = { ...values};
      await sendForgetPasswordLink(payload);
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: 'Reset password link has been sent to your email!',
      });
    } catch (e: any) {
      // useNotificationStore.getState().addNotification({
      //   type: 'error',
      //   title: 'Error',
      //   message: e.message,
      // });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="column d-flex align-items-center">
      <div>
        <p className="title">
          Forgot Password?
        </p>
        <p className="text-muted description mb-4">
          Enter your email and we'll send you instructions to reset your password
        </p>
        <Form<ForgetPasswordValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
          {({ register, formState }) => (
            <>
              <InputField
                type="email"
                label="Email Address"
                error={formState.errors['email']}
                registration={register('email')}
                placeholder="Email Address"
              />
              <Button isLoading={loading} type="submit" className="w-full">
                Send Reset Link
              </Button>
              <p className="text-center loggedIn text-muted">
                <Link to={`/auth/login`} className="text-decoration-none">
                  <i className="fa-solid fa-chevron-left pe-2" />
                  Back to login
                </Link>
              </p>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

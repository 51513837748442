import React, { useState } from 'react'
import '../routes/auth.css'
import * as z from 'zod';
import { Form, InputField, InputPhone, SelectField } from '@/components/Form';
import { UserContractValues } from '../types';
import { Button } from '@/components/Elements';
import { addContract } from '../api/addContract';
import { useNotificationStore } from '@/stores/notifications';
import { useNavigate } from 'react-router-dom';


const schema = z.object({
    name: z.string().min(1, 'Please enter your name'),
    second_name: z.string().min(1, 'Please enter your second name'),
    other_name: z.string(),
    email: z.string().min(1, 'Please enter your email').email('Please enter a valid Email'),
    id_no: z
        .string()
        .min(1, 'Please Enter Id no.')
        .regex(/^\d+$/)
        .transform(Number),
    passport: z
        .string()
        .min(1, 'Please enter your passport no.')
        .regex(/[a-zA-Z0-9]/, "Enter valid passport no."),
    payment: z.string().min(1, 'Please choose a payment method'),
    address_1: z.string().min(1, 'Please enter your address'),
    residence: z.string().min(1, 'Please choose a residential status'),
    postal_address : z.string().min(1, 'Please enter your postal address'),
    phone: z.string().min(1, 'Please enter your Phone Number')
})

const paymentOptions = [
    {
        label: 'Installments',
        value: 'Installments'
    },
    {
        label: 'Financed',
        value: 'Financed'
    },
    {
        label: 'Cash',
        value: 'Cash'
    }
]

const residentialOptions = [
    {
        label: 'Resident',
        value: 'Resident'
    },
    {
        label: 'Non-resident',
        value: 'Non-resident'
    }
]

const ContractEditForm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <>
            <div className="w-full px-8">
                <div className="bg-white mt-8 mb-8 py-8 px-8 shadow sm:rounded-lg sm:px-12 w-full h-full">
                    <div className="heading">
                        <h4 className='font-weight-bold'>KYC FORM</h4>
                        <p className='mb-4'>Your data remains confidential and will be be treated as private</p>
                    </div>
                    <Form<UserContractValues, typeof schema>
                        schema={schema}
                        onSubmit={async (values) => {
                            setLoading(true)
                            try {
                                const calculatePercentage = () => {
                                    const totalFields = Object.keys(values).length - 1;
                                    const filledFields = Object.values(values).filter(Boolean).length - 1;
                                    return Math.round((filledFields / totalFields) * 100);
                                };
                                const payload = {
                                    ...values,
                                    progress: calculatePercentage()
                                }
                                await addContract(payload)
                                useNotificationStore.getState().addNotification({
                                    type: 'success',
                                    title: 'success',
                                    message: 'Contract Created successfuly'
                                })
                                setLoading(false)
                                navigate('/')

                            } catch (error: any) {
                                setLoading(false)
                                useNotificationStore.getState().addNotification({
                                    type: 'error',
                                    title: 'error',
                                    message: error.message,
                                })
                            }

                        }}
                    >
                        {({ register, formState }) => (
                            <>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <InputField
                                            label='First Name'
                                            type='text'
                                            className="form-control"
                                            placeholder="Name"
                                            error={formState.errors['name']}
                                            registration={register('name')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputField
                                            label='Second Name'
                                            type='text'
                                            className="form-control"
                                            placeholder="Second Name"
                                            error={formState.errors['second_name']}
                                            registration={register('second_name')}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-6">
                                        <InputField
                                            label='Other Name'
                                            type='text'
                                            className="form-control"
                                            placeholder="Other name"
                                            error={formState.errors['other_name']}
                                            registration={register('other_name')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputField
                                            label='Email'
                                            type='email'
                                            className="form-control"
                                            placeholder="Email"
                                            error={formState.errors['email']}
                                            registration={register('email')}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-6">
                                        <InputPhone
                                            label='Phone'
                                            error={formState.errors['phone']}
                                            registration={register('phone')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputField
                                            label='ID No'
                                            type='number'
                                            className="form-control"
                                            placeholder="Id no"
                                            error={formState.errors['id_no']}
                                            registration={register('id_no')}
                                        />
                                    </div>


                                </div>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <InputField
                                            label='Passport No'
                                            type='text'
                                            className="form-control"
                                            placeholder="Passport No"
                                            error={formState.errors['passport']}
                                            registration={register('passport')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectField
                                            label='Choice of Payment'
                                            error={formState.errors['payment']}
                                            registration={register('payment')}
                                            options={paymentOptions}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-6">
                                        <SelectField
                                            label='Residential Status'
                                            error={formState.errors['residence']}
                                            registration={register('residence')}
                                            options={residentialOptions}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <InputField
                                            label="Purchaser Address"
                                            type='text'
                                            className="form-control"
                                            placeholder="Purchaser Address"
                                            error={formState.errors['address_1']}
                                            registration={register('address_1')}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <InputField
                                        label='Postal Address'
                                        type='text'
                                        className='form-control'
                                        placeholder='Postal Address'
                                        error={formState.errors['postal_address']}
                                        registration={register('postal_address')}
                                        />
                                    </div>
                                </div>
                                <div className="btn">
                                    <Button
                                        type='submit'
                                        variant='primary'
                                        isLoading={loading}
                                    >
                                        SAVE DATA
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>

                </div>
            </div >
        </>
    )
}

export default ContractEditForm
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Table, TableColumn, ConfirmationDialog } from '@/components/Elements';
import { Subadmin } from '@/types';
import { useDisclosure } from '@/hooks/useDisclosure';
import { blockSubAdmin } from '../api/blockSubAdmin';
import { deleteSubAdmin } from '../api/deleteSubAdmin';
import { useSubAdmins } from '../api/getSubAdmins';
import { useState } from 'react';

const SubAdmin = () => {
  const subAdminList = useSubAdmins();
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string | null>();
  const { open: blockOpen, close: blockClose, isOpen: isBlockOpen } = useDisclosure();

  const newData: Subadmin[] = subAdminList?.data ?? [];


  const handleBlock = async (id: string) => {
    setUserId(id);
    blockOpen();
  };
  const blockUser = async () => {
    if (userId) {
      await blockSubAdmin(userId);
      subAdminList.refetch();
      blockClose();
    }
  };

  const columns: TableColumn<Subadmin>[] = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Phone',
      field: 'phone_number',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Actions',
      field: 'action',
      Cell({ entry }) {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-outline-dark icon me-2"
              title="Edit"
              onClick={() => navigate(`/create?id=${entry.id}`)}
            >
              <div>
                <span className="mx-2">
                  <i className="fa-solid fa-user-pen"></i>
                </span>
              </div>
            </button>
            <button
              title={entry.status === 'inactive' ? 'activate' : 'de-activate'}
              className="btn btn-outline-dark icon me-2"
              onClick={() => handleBlock(entry.id)}
            >
              <div>
                <span className="mx-2">
                  {entry.status === 'inactive' ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-ban"></i>
                  )}
                </span>
              </div>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="container my-4">
        <div className="main-dashboard">
          <div className="headings">
            <h3>Sub-Admin</h3>
            <p>You can view all subadmins here</p>
          </div>
          <div className="add-btn">
            <NavLink to="/create">
              <Button style={{ float: 'right', marginRight: '35px', marginTop: '-30px' }}>
                Add
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="table-structure" style={{ marginTop: '90px' }}>
          <Table data={newData} columns={columns} />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isBlockOpen}
        close={blockClose}
        icon="danger"
        title="Confirmation"
        body="Are you sure you want to do this action?"
        confirmButton={
          <Button type="button" className="bg-red-600" onClick={blockUser}>
            Confirm
          </Button>
        }
      />
    </>
  );
};

export default SubAdmin;

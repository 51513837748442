import { Link } from 'react-router-dom';
import * as z from 'zod';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import '../routes/auth.css';

const schema = z.object({
  email: z.string().min(1, 'Please Enter Email'),
  password: z.string().min(1, 'Please Enter Password'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              blueLabel
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              blueLabel
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <div className="d-flex flex-row w-full">
                <div className="my-2">
                  <Link
                    to={`/auth/forget`}
                    className="loggedIn text-decoration-none" 
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <Button
                startIcon={<i className="fa-solid fa-lock" />}
                isLoading={isLoggingIn}
                type="submit"
                className="w-full"
              >
                Log In
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

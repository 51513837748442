import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Subadmin } from '@/types';

export const getSubAdmins = async (): Promise<Subadmin[]> => {
  return await axios.get(`/users/getSubadmin`);
};

type QueryFnType = typeof getSubAdmins;

type useSubAdminsList = {
  config?: QueryConfig<QueryFnType>;
};

export const useSubAdmins = ({ config }: useSubAdminsList = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['AllSubAdmins'],
    queryFn: () => getSubAdmins(),
  });
};

import { useQuery } from 'react-query';

import { axiosMain } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ContractDetail } from '@/types';

export const getAllContracts = async (): Promise<ContractDetail[]> => {
  return await axiosMain.get(`/contracts/allContracts`);
};

type QueryFnType = typeof getAllContracts;

type useContractList = {
  config?: QueryConfig<QueryFnType>;
};

export const useAllContracts = ({ config }: useContractList = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['AllSubAdminsContracts'],
      queryFn: () => getAllContracts(),
    });
  };
  
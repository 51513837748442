import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Image, baseUrl } from '@/components/Elements/Image/Image';
import * as z from 'zod';
import './editprofile.css'
import { useAuth } from '@/lib/auth';
import { Form, InputField, InputPhone } from '@/components/Form';
import { useNotificationStore } from '@/stores/notifications';
import { Edit, LoginValues } from '@/types';
import { Spinner } from '@/components/Elements';
import { editProfile } from './api/editProfileApi';
import { getUpdatedProfile, uploadImage } from './api/getUpdatedProfile';
import { API_URL } from '@/config';


const EditProfile = () => {
    const auth = useAuth();
    const [reload, setReload] = useState(false);
    const [response, setResponse] = useState<Edit>();
    // const [image, setImage] = useState<File | null | undefined>();
    const [file, setFile] = useState<File | null>(null);
    const [profilePicture, setPicturePreview] = useState<string>('');

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id: string | null = searchParams.get('id');

    const handleEditProfile = async () => {
        const res = await editProfile(id)
        setResponse(res);
    }

    const schema = z.object({
        name: z.string().min(1, 'Required'),
        phone: z.string().min(1, 'Required'),
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setPicturePreview(URL.createObjectURL(e.target.files[0]));
            setFile(e.target.files[0]);
        }
    };

    useEffect(() => {
        setReload(true);
        handleEditProfile()
        setPicturePreview(`${baseUrl}/${auth.user?.imageUrl ?? ''}`);
        setTimeout(() => {
            setReload(false);
            auth.refetchUser();
        }, 1000)
    }, [])

    return (
        <>
            <div className="mx-5 my-4">
                <h3>Edit Profile</h3>
                <div className="main-box">
                    {reload && <Spinner />}
                    {!reload && (
                        <>
                            <div className="my-4">
                                <form className="space-y-6">
                                    <Image src={profilePicture} alt="profile pic" className="rounded-full object-cover" style={{ height: "130px", width: "130px" }} />
                                    <input type="file" id='file' onChange={handleChange} />
                                </form>
                            </div>
                            <Form<LoginValues, typeof schema>
                                schema={schema}
                                onSubmit={async (data: LoginValues, event) => {
                                    const payload = {
                                        name: data?.name,
                                        phone: data?.phone,
                                    };
                                    await getUpdatedProfile(id, payload)
                                    
                                    if (file) {
                                        await uploadImage({ profilePicture: file }, auth?.user?.id ?? '');
                                        auth.refetchUser();
                                    }
                                    useNotificationStore.getState().addNotification({
                                        type: 'success',
                                        title: 'Success',
                                        message: 'Profile edited successfuly',
                                    });
                                    event?.target.reset()
                                    navigate('/')
                                }}
                                options={{
                                    defaultValues: {
                                        name: response?.name,
                                        phone: response?.phone_number,
                                    },
                                }}
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="row mt-4">
                                            <div className="col-6">
                                                <InputField
                                                    type="text"
                                                    label="Name"
                                                    error={formState.errors['name']}
                                                    registration={register('name')}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-6">
                                                <InputPhone
                                                    registration={register('phone')}
                                                    value={response?.phone_number}
                                                    label="Phone Number"
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-4">
                                                <button type="submit" className="btn btn-primary">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="mx-2">UPDATE</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Form>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default EditProfile
import * as React from 'react';
import logo from '@/assets/stixLogo.png'
import loginImg from '@/assets/loginImage.jpg'
import footerLogo from '@/assets/footer_logo.png'
import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';
import './login.css'


type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const LoginLayout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8" style={{ backgroundColor: "#f7f4f1" }}>
        <div style={{ minHeight: "60vh", width: "80%", marginLeft: "120px" }}>
          <div className="row main-section">

            <div className="col-6 login-left">
              <img
                className='login-img'
                src={loginImg}
                alt="Workflow"
              />
            </div>
            <div className="col-6">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className=" mt-4 flex justify-center">
                  <Link className="flex items-center text-white" to="/">
                    <img
                      className="h-24 logo-img"
                      width="400"
                      src={logo}
                      alt="Workflow"
                    />
                  </Link>
                </div>
              </div>
              <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{children}</div>
              </div>
              <div className='d-flex justify-content-center mt-4'>
                <img src={footerLogo} className='footer-img' alt="powered by" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import CreateUser from '@/features/owner/routes/CreateUser';
import SubAdmin from '@/features/owner/routes/SubAdmin';
import { lazyImport } from '@/utils/lazyImport';
import ContractTemplates from '@/features/owner/routes/ContractTemplates';
import EditProfile from '@/features/profile/EditProfile';
import ResetPassword from '@/features/auth/components/ResetPassword';
import { ContractEdit } from '@/features/owner/routes/ContactEdit';
import { AdminViewContracts } from '@/features/owner/routes/AdminContracts';
import EditAdminContract from '@/features/owner/routes/EditAdminContract';
import ContractEditForm from '@/features/auth/components/ContractEditForm';

const { Dashboard } = lazyImport(() => import('@/features/owner'), 'Dashboard');
const { Contract } = lazyImport(() => import('@/features/owner'), 'Contract');
const { Contracts } = lazyImport(() => import('@/features/owner'), 'Contracts');
const { Profile } = lazyImport(() => import('@/features/profile/Profile'), 'Profile');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'contract', element: <Contract /> },
      { path: 'contracts', element: <Contracts /> },
      { path: 'contract-templates', element: <ContractTemplates /> },
      { path: '', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
      { path: 'sub', element: <SubAdmin /> },
      { path: 'profile', element: <Profile /> },
      { path: 'create', element: <CreateUser /> },
      { path: 'editprofile', element: <EditProfile /> },
      { path: 'resetpassword', element: <ResetPassword /> },
      { path: 'contract-edit', element: <ContractEdit /> },
      { path: 'contract-edit/:id', element: <ContractEdit /> },
      { path: 'all-contracts', element: <AdminViewContracts /> },
      { path: 'edit-admin-contract', element: <EditAdminContract/> },
      { path: 'kyc-form', element: <ContractEditForm/> }
    ],
  },
];

import { useEffect, useState } from 'react';
import { useSearchParams,useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Form, InputField, InputPhone } from '@/components/Form';
import { useNotificationStore } from '@/stores/notifications';
import { LoginValues, Subadmin } from '@/types';

import { editSubAdmin } from '../api/editSubAdmin';
import { createSubAdmins, updateSubAdmin } from '../api/postSubAdmins';
import { Spinner } from '@/components/Elements';

const CreateUser = () => {
  const [response, setResponse] = useState<Subadmin>();
  const [reload, setReload] = useState(false);
  const [showPass, setShowPass] = useState<boolean>(true);
  const navigate=useNavigate();

  const schema = z.object({
    email: z.string().min(1, 'Required'),
    password: z.string().min(1, 'Required'),
    name: z.string().min(1, 'Required'),
    phone: z.string().min(1, 'Required'),
  });

  const newSchema = z.object({
    email: z.string().min(1, 'Required'),
    password: z.string().min(1).optional().or(z.literal('')),
    name: z.string().min(1, 'Required'),
    phone: z.string().min(1, 'Required'),
  });

  const [searchParams] = useSearchParams();
  const id: string | null = searchParams.get('id');

  const handleEdit = async () => {
    const res = await editSubAdmin(id);
    setResponse(res);
  };

  useEffect(() => {
    if (id) {
      setReload(true);
      handleEdit();
      setShowPass(false);
      setTimeout(() => {
        setReload(false);
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className="mx-5 my-4">
        <h3>{!id ? 'Add new Sub-admin' : 'Edit Sub-admin'}</h3>
        <div className="main-box">
        {reload && <Spinner/>}
          {!reload && (
            <Form<LoginValues, typeof schema>
              schema={!id ? schema : newSchema}
              onSubmit={async (data: LoginValues, event) => {
                const payload = {
                  name: data?.name,
                  email: data?.email,
                  phone: data?.phone,
                  password: data?.password,
                  role: 'subadmin',
                  status: 'active',
                };
                if (id === null) {
                  await createSubAdmins(payload);
                } else {
                  await updateSubAdmin(payload, id);
                }
                useNotificationStore.getState().addNotification({
                  type: 'success',
                  title: 'Success',
                  message: `${!id ? 'Sub-admin added successfuly' : 'Sub-admin edited sucessfuly'}`,
                });
                navigate('/sub')
              }}
              options={{
                defaultValues: {
                  name: response?.name,
                  email: response?.email,
                  phone: response?.phone_number,
                },
              }}
            >
              {({ register, formState }) => (
                <>
                  <div className="row mt-4">
                    <div className="col-6">
                      <InputField
                        type="text"
                        label="Name"
                        error={formState.errors['name']}
                        registration={register('name')}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <InputPhone
                        registration={register('phone')}
                        value={response?.phone_number}
                        label="Phone Number"
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <InputField
                        type="email"
                        label="Email"
                        error={formState.errors['email']}
                        registration={register('email')}
                      />
                    </div>
                  </div>

                  {showPass && (
                    <div className="row mt-4">
                      <div className="col-6">
                        <InputField
                          type="password"
                          label="Password"
                          className="email-inp"
                          error={formState.errors['password']}
                          registration={register('password')}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row mt-4">
                    <div className="col-4">
                      {id === null && (
                        <button type="submit" className="btn btn-primary">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mx-2">ADD</span>
                          </div>
                        </button>
                      )}
                      {id !== null && (
                        <button type="submit" className="btn btn-primary">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mx-2">UPDATE</span>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateUser;

import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Table, TableColumn, ConfirmationDialog } from '@/components/Elements';
import { Contract, ContractDetail } from '@/types';
import { useState } from 'react';
import { useAuth } from '@/lib/auth';
import { useAllContracts } from '../api/contract/getAllSubadminsContracts';
import moment from 'moment';

export const AdminViewContracts = () => {
  const { user } = useAuth();
  const contractList = useAllContracts();
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string | null>();
  const newData: ContractDetail[] = contractList?.data ?? [];
  const handleView = async (id: string) => {
    setUserId(id);
  };



  const columns: TableColumn<any>[] = [
    {
      title: 'Name',
      field: 'contract_name',
      Cell({ entry: {name} }) {
        return <div className="text-center">{name}</div>
      }
    },
    {
      title: 'Created',
      field: 'createdAt',
      Cell({ entry: { createdAt } }) {
        return <div>{moment(createdAt).format('lll')}</div>;
      },
    },
    {
      title: 'Last Modified',
      field: 'updatedAt',
      Cell({ entry: { updatedAt } }) {
        return <div>{moment(updatedAt).format('lll')}</div>;
      },
    },
    {
      title: 'Vendor Edited',
      field: 'isVendorEdit',
      Cell({ entry: { isVendorEdit } }) {
        return <div className="text-center">{isVendorEdit === true ? "YES" : "NO"}</div>;
      },
    },
    {
      title: 'Actions',
      field: 'action',
      Cell({ entry }) {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              style={{ float: 'right', marginRight: '35px' }}
              onClick={() => navigate(`/contract-edit/${entry._id}`)}
            >
              view
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="container my-4">
        <div className="main-dashboard">
          <div className="headings">
            <h3>Contracts</h3>
            <p>You can view all contracts here</p>
          </div>
          {/*   <div className="add-btn">
            <NavLink to="/contract-edit">
              <Button
                style={{ float: 'right', marginRight: '35px', marginTop: '-30px' }}
                onClick={() => navigate('/contract-edit')}
              >
                Add
              </Button>
            </NavLink>
          </div> */}
        </div>
        <div className="table-structure" style={{ marginTop: '90px' }}>
          <Table data={newData} columns={columns} />
        </div>
      </div>
    </>
  );
};

import { LoginValues } from '@/types';

import { axios } from '../../../lib/axios';

export const createSubAdmins = (data: LoginValues): Promise<void> => {
  return axios.post('users/addSubadmin', data);
};

export const updateSubAdmin = (data: LoginValues, id: string | null) => {
  return axios.post(`users/updateSubadmin/${id}`, data);
};

import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

const errorFunction = (error: any) => {
  if (error.response.status === 403) {
    storage.clearToken();
    window.location.assign(window.location.origin as unknown as string);
    return;
  }

  const message = error.response?.data?.message || error.message;
  useNotificationStore.getState().addNotification({
    type: 'error',
    title: 'Failure',
    message,
  });

  return Promise.reject(error);
};

export const axios = Axios.create({
  baseURL: `${API_URL}/v1`,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use((response) => {
  return response.data;
}, errorFunction);

export const axiosMain = Axios.create({
  baseURL: `${API_URL}/v1`,
});

axiosMain.interceptors.request.use(authRequestInterceptor);
axiosMain.interceptors.response.use((response) => {
  return response.data.data;
}, errorFunction);

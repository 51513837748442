import { axios } from "@/lib/axios"
import { EditTemplate } from "../routes/types/adminTypes"

export type UploadLogoDTO = {
    templateLogo: File;
}

export const postTemplateValues  = (payload : EditTemplate) => {
    return axios.post(`/contracts/edited-template`, payload)
}

export const uploadLogo = async (data: UploadLogoDTO) => {
    const form = new FormData();
    const extension = data.templateLogo.name.split('.').at(-1);
    const name = new Date().getTime() + 'image.' + extension;
    form.append('template_logo', data.templateLogo, name);
    return axios.post('/contracts/upload', form)
}


import { axios } from "@/lib/axios"
import { EditTemplate } from "../routes/types/adminTypes"
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export const getTemplate = async (): Promise<EditTemplate> => {
    return await axios.get('/contracts/get-template')
} 

type QueryFnType = typeof getTemplate;
type UseTemplate = {
    config?: QueryConfig<QueryFnType>
}

export const useTemplate = ( {config}: UseTemplate = {} ) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['Template'],
        queryFn: () => getTemplate(),
    })
}

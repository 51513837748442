import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline';
import * as React from 'react';

import { Button } from '@/components/Elements/Button';
import { Dialog, DialogTitle } from '@/components/Elements/Dialog';

export type ConfirmationDialogProps = {
  isOpen: boolean;
  close: () => void;
  confirmButton: React.ReactElement;
  title: string;
  body?: string;
  cancelButtonText?: string;
  icon?: 'danger' | 'info';
  isDone?: boolean;
};

export const ConfirmationDialog = ({
  isOpen,
  close,
  confirmButton,
  title,
  body = '',
  cancelButtonText = 'Cancel',
  icon = 'danger',
  isDone = false,
}: ConfirmationDialogProps) => {
  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  const cancelButtonRef = React.useRef(null);

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  return (
    <>
      <Dialog isOpen={isOpen} onClose={close} initialFocus={cancelButtonRef}>
        <div className="sm:flex sm:items-start">
          {icon === 'danger' && (
            <div className="mx-3 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:h-10 sm:w-10">
              <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
          )}

          {icon === 'info' && (
            <div className="mx-3 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:h-10 sm:w-10">
              <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
            </div>
          )}
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <DialogTitle className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </DialogTitle>
            {body && (
              <div className="mt-2">
                <p className="text-sm text-gray-500">{body}</p>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 flex space-x-2 justify-end">
          <Button variant="outline" onClick={close}>
            {cancelButtonText}
          </Button>
          {confirmButton}
        </div>
      </Dialog>
    </>
  );
};

import { useQuery } from 'react-query';

import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Subadmin } from '@/types';

import { axios } from '../../../lib/axios';

export const editSubAdmin = (id: string | null): Promise<Subadmin> => {
  return axios.get(`users/editSub/${id}`);
};

type QueryFnType = typeof editSubAdmin;

type editSubAdminList = {
  config?: QueryConfig<QueryFnType>;
};

export const useEditSubAdmin = (id: string | null, { config }: editSubAdminList = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['EditSubAdmins'],
    queryFn: () => editSubAdmin(id),
  });
};

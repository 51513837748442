import { useQuery } from 'react-query';

import { axiosMain } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { UserContractValues } from '@/features/auth';

export const getContract = async (id: any): Promise<UserContractValues> => {
  return await axiosMain.get(`/contracts/${id}`);
};

type QueryFnType = typeof getContract;

type useContractList = {
  config?: QueryConfig<QueryFnType>;
};

export const useContract = (id: string | null, { config }: useContractList = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [`Contract-${id}`],
    queryFn: () => getContract(id),
    enabled: id != null || id != undefined,
  });
};

import { useState } from 'react'
import { useAuth } from '@/lib/auth'
import * as z from 'zod';
import { Form, InputField } from '@/components/Form';
import { Button } from '@/components/Elements';
import { useNotificationStore } from '@/stores/notifications';
import { ResetPasswordValues } from '@/types';
import { postResetPass } from '../api/postResetPass';
import { useNavigate } from 'react-router-dom';
const schema = z
    .object({
        old_password: z.string().min(1, 'Required'),
        new_password: z
            .string()
            .min(1, 'Please enter password')
            .regex(
                /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@$#%]).*$/,
                'Password should be a combination of uppercase alphabets, numbers and special characters'
            ),
        confirm_password: z.string().min(1, 'Please enter confirm password'),
    })
    .superRefine(({ confirm_password, new_password }, ctx) => {
        if (confirm_password !== new_password) {
            ctx.addIssue({
                path: ['confirm_password'],
                code: 'custom',
                message: 'Password did not match',
            });
        }
    });


const ResetPassword = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const id : string = user?.id ?? ''
    const [loading, setLoading] = useState<boolean>(false)


    return (
        <>
            <div className="mx-5 my-4">
                <h3>Change Password</h3>
                <div className="main-box">
                    <div className="my-4">
                        <Form<ResetPasswordValues, typeof schema>
                            schema={schema}
                            onSubmit={async (data: ResetPasswordValues, event) => {
                                const payload = {
                                    old_password: data?.old_password,
                                    new_password: data?.new_password,
                                }
                                await postResetPass(id, payload)
                                useNotificationStore.getState().addNotification({
                                    type: 'success',
                                    title: 'Success',
                                    message: 'Password changed successfuly',
                                });
                                event?.target.reset();
                                navigate('/')
                            }}
                            
                        >
                            {({ register, formState }) => (
                                <>
                                    <div className="col-6">
                                        <InputField
                                            type="password"
                                            label="Old Password"
                                            error={formState.errors['old_password']}
                                            registration={register('old_password')}
                                            placeholder="New Password"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputField
                                            type="password"
                                            label="New Password"
                                            error={formState.errors['new_password']}
                                            registration={register('new_password')}
                                            placeholder="New Password"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputField
                                            type="password"
                                            label="Confirm Password"
                                            error={formState.errors['confirm_password']}
                                            registration={register('confirm_password')}
                                            placeholder="Confirm Password"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Button isLoading={loading} type="submit" className="w-full">
                                            Save
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>

            </div>


        </>

    )
}

export default ResetPassword
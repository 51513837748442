import React, { useEffect, useRef, useState } from 'react';
import logo from '@/assets/logo-company.png';
import { Button, Spinner } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { axiosMain } from '@/lib/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useContract } from '../api/contract/getContract';
import { useContracts } from '../api/contract/getContracts';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';
import * as z from 'zod';
import 'react-circular-progressbar/dist/styles.css';
import { Form, InputField, InputPhone, SelectField } from '@/components/Form';
import { API_URL } from '@/config';
import { ExportContractValues, VendorContractValues } from '@/features/auth';
import moment from 'moment';


const schema = z.object({
  name: z.string().min(1, 'Required'),
  second_name: z.string().min(1, 'Required'),
  other_name: z.string(),
  email: z.string().min(1, 'Required').email('Please enter a valid Email'),
  id_no: z
    .string()
    .min(1, 'Please Enter Id no.')
    .regex(/^\d+$/)
    .transform(Number),
  passport: z
    .string()
    .min(1, 'Please enter your passport no.')
    .regex(/[a-zA-Z0-9]/, "Enter valid passport no."),
  payment: z.string().min(1, 'Please choose a payment method'),
  address_1: z.string().min(1, 'Please enter your address'),
  residence: z.string().min(1, 'Please choose a residential status'),
  postal_address: z.string().min(1, 'Please enter your postal address'),
  phone: z.string().min(1, 'Please enter your Phone Number').regex(/[0-9]/, "Enter valid phone number."),
  date: z.preprocess((arg) => {
    if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
  }, z.date()),
  vendor_cost_1: z
    .string()
    .min(1, 'Please Enter Advocate Cost for Phase 1 Management Company')
    .regex(/^\d+$/)
    .transform(Number),
  vendor_cost_2: z
    .string()
    .min(1, 'Please Enter Advocate Cost for Residential Management Company')
    .regex(/^\d+$/)
    .transform(Number),
  vendor_cost_4: z
    .string()
    .min(1, 'Please enter transfer/allotment cost')
    .regex(/^\d+$/)
    .transform(Number),
  vendor_cost_5: z
    .string()
    .min(1, 'Please enter cost of transfer/allotment')
    .regex(/^\d+$/)
    .transform(Number),
  legal_fees_1: z
    .string()
    .min(1, 'Please enter legal fees for undertaking the transfer of the reversionary interest')
    .regex(/^\d+$/)
    .transform(Number),
  legal_fees_2: z
    .string()
    .min(1, 'Please enter Purchaser’s share of the reversionary interest in the Phase 1 Land ')
    .regex(/^\d+$/)
    .transform(Number),
  electricity_charge: z
    .string()
    .min(1, 'Please enter electricity and connection charges')
    .regex(/^\d+$/)
    .transform(Number),
  water_charge: z
    .string()
    .min(1, 'Please enter water charges')
    .regex(/^\d+$/)
    .transform(Number),
  final_costs: z
    .string()
    .min(1, 'Please enter valid number')
    .regex(/^\d+$/)
    .transform(Number),
  initial_charge_1: z
    .string()
    .min(1, 'Please enter initial provisional service charge')
    .regex(/^\d+$/)
    .transform(Number),
  initial_charge_2: z
    .string()
    .min(1, 'Please enter initial provisional service charge deposit')
    .regex(/^\d+$/)
    .transform(Number),
  initial_charge_3: z
    .string()
    .min(1, 'Please enter valid number')
    .regex(/^\d+$/)
    .transform(Number),
  initial_charge_4: z
    .string()
    .min(1, 'Please enter valid number')
    .regex(/^\d+$/)
    .transform(Number),
  agreement_for_sale_text: z.string().min(1, 'Please enter aggrement for sale description'),
  lease_text_1: z
    .string()
    .min(1, 'Please enter lease premium payable by purchaser')
    .regex(/^\d+$/)
    .transform(Number),
  lease_text_2: z
    .string()
    .min(1, 'Please enter lease premium payable before the execution of this agreement')
    .regex(/^\d+$/)
    .transform(Number),
  lease_text_3: z
    .string()
    .min(1, 'Please enter lease premium payable before the completion date')
    .regex(/^\d+$/)
    .transform(Number),
  legal_fees: z
    .string()
    .min(1, 'Please enter legal fees')
    .regex(/^\d+$/)
    .transform(Number),
  office_charge: z
    .string()
    .min(1, 'Please enter office disbursement charges')
    .regex(/^\d+$/)
    .transform(Number),
  vat_charge: z
    .string()
    .min(1, 'Please enter VAT charges')
    .regex(/^\d+$/)
    .transform(Number),
  deposit_charge_1: z
    .string()
    .min(1, 'Please enter VAT charges')
    .regex(/^\d+$/)
    .transform(Number),
  deposit_charge_2: z
    .string()
    .min(1, 'Please enter VAT charges')
    .regex(/^\d+$/)
    .transform(Number),
  stamp_cost_1: z
    .string()
    .min(1, 'Please enter estimated stamp duty on the Lease (being 4% of the Lease Premium')
    .regex(/^\d+$/)
    .transform(Number),
  stamp_cost_2: z
    .string()
    .min(1, 'Please enter estimated stamp duty on the charge being 0.1% of the financed amount')
    .regex(/^\d+$/)
    .transform(Number),
  stamp_cost_3: z
    .string()
    .min(1, 'Please enter estimated stamping and registration costs')
    .regex(/^\d+$/)
    .transform(Number),
});


const residentialOptions = [
  {
    label: 'Resident',
    value: 'Resident'
  },
  {
    label: 'Non-resident',
    value: 'Non-resident'
  }
]


export const ContractEdit = () => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();
  const contractsQuery = useContracts();
  const contract = useContract(id);
  let newData = contract?.data
  const submitBtn = useRef();
  const [inputReadOnly, setInputReadOnly] = useState(false);
  const [saveClick, setSaveClick] = useState<boolean>(false)
  const [exportData, setExportData] = useState<ExportContractValues>()
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [exportLoad, setExportLoad] = useState<boolean>(false);

  useEffect(() => {
    if (user?.role === 'admin') {
      setInputReadOnly(true);
    }
  }, []);

  const handleExportBtn = async () => {
    setExportLoad(true)
    const fileName = await axiosMain.post('/users/export', { id: id });
    if (fileName) {
      setExportLoad(false);
      window.open(`${API_URL}/${fileName}`);
    } else {
      setExportLoad(false);
    }
  };


  const paymentOptions = [
    {
      label: 'Installments',
      value: 'Installments'
    },
    {
      label: 'Financed',
      value: 'Financed'
    },
    {
      label: 'Cash',
      value: 'Cash'
    }
  ]
  useEffect(() => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 2000);
  }, [])

const curDate = moment().format('YYYY-MM-DD');
const existDate = moment(newData?.date).format('YYYY-MM-DD');


  return (
    <ContentLayout title="Draft Lease Agreement">
      <p>Your data remains confidential and will be be treated as private</p>
      <div
        style={{
          paddingBottom: 1,
          backgroundColor: '#F3F4F6',
          zIndex: 100,
        }}
      >
        {reload && <Spinner />}
        {!reload &&
          <div>
            <div>
              <Form<VendorContractValues, typeof schema>
                schema={schema}
                onSubmit={async (values: VendorContractValues) => {
                  setSaveClick(true)
                  setLoading(true);
                  if (id) {
                    const res: ExportContractValues = await axiosMain.put(`/contracts/${id}`, values);
                    setExportData(res);

                    addNotification({
                      type: 'success',
                      title: 'Contract Updated',
                    });
                    setLoading(false);
                    navigate('/contracts')
                  } else {
                    await axiosMain.post(`/contracts/add-contract`, {
                      contract_name: values.contract_name,
                    });
                    setLoading(false);
                    addNotification({
                      type: 'success',
                      title: 'Contract Added',
                    });
                  }
                  contractsQuery.refetch();

                }}
                id="my-form"
                options={{
                  defaultValues: {
                    name: newData?.name,
                    second_name: newData?.second_name,
                    other_name: newData?.other_name,
                    email: newData?.email,
                    phone: newData?.phone,
                    id_no: newData?.id_no.toString(),
                    passport: newData?.passport,
                    payment: newData?.payment,
                    residence: newData?.residence,
                    address_1: newData?.address_1,
                    date: existDate ? existDate : curDate,
                    postal_address: newData?.postal_address,
                    vendor_cost_1: newData?.isVendorEdit === true ? newData.vendor_cost_1.toString() : '',
                    vendor_cost_2: newData?.isVendorEdit === true ? newData?.vendor_cost_2.toString() : '',
                    vendor_cost_4: newData?.isVendorEdit === true ? newData?.vendor_cost_4.toString() : '',
                    vendor_cost_5: newData?.isVendorEdit === true ? newData?.vendor_cost_5.toString() : '',
                    legal_fees_1: newData?.isVendorEdit === true ? newData?.legal_fees_1.toString() : '',
                    legal_fees_2: newData?.isVendorEdit === true ? newData?.legal_fees_2.toString() : '',
                    electricity_charge: newData?.isVendorEdit === true ? newData?.electricity_charge.toString() : '',
                    water_charge: newData?.isVendorEdit === true ? newData?.water_charge.toString() : '',
                    final_costs: newData?.isVendorEdit === true ? newData?.final_costs.toString() : '',
                    initial_charge_1: newData?.isVendorEdit === true ? newData?.initial_charge_1.toString() : '',
                    initial_charge_2: newData?.isVendorEdit === true ? newData?.initial_charge_2.toString() : '',
                    initial_charge_3: newData?.isVendorEdit === true ? newData?.initial_charge_3.toString() : '',
                    initial_charge_4: newData?.isVendorEdit === true ? newData?.initial_charge_4.toString() : '',
                    agreement_for_sale_text: newData?.agreement_for_sale_text,
                    lease_text_1: newData?.isVendorEdit === true ? newData?.lease_text_1.toString() : '',
                    lease_text_2: newData?.isVendorEdit === true ? newData?.lease_text_2.toString() : '',
                    lease_text_3: newData?.isVendorEdit === true ? newData?.lease_text_3.toString() : '',
                    legal_fees: newData?.isVendorEdit === true ? newData?.legal_fees.toString() : '',
                    office_charge: newData?.isVendorEdit === true ? newData?.office_charge.toString() : '',
                    vat_charge: newData?.isVendorEdit === true ? newData?.vat_charge.toString() : '',
                    deposit_charge_1: newData?.isVendorEdit === true ? newData?.deposit_charge_1.toString() : '',
                    deposit_charge_2: newData?.isVendorEdit === true ? newData?.deposit_charge_2.toString() : '',
                    stamp_cost_1: newData?.isVendorEdit === true ? newData?.stamp_cost_1.toString() : '',
                    stamp_cost_2: newData?.isVendorEdit === true ? newData?.stamp_cost_2.toString() : '',
                    stamp_cost_3: newData?.isVendorEdit === true ? newData?.stamp_cost_3.toString() : '',
                  }
                }}
              >
                {({ register, formState }) => (
                  <>

                    <div className='d-flex flex-row'>
                      <div style={{ position: "absolute", right: "26px", top: "50px" }}>
                        <>
                          {saveClick || newData?.isVendorEdit === true ? (
                            <Button
                              variant="outline"
                              onClick={handleExportBtn}
                              style={{ marginRight: "18px" }}
                              isLoading={exportLoad}

                            >
                              Export as doc
                            </Button>
                          ) : null}
                          <Button isLoading={loading} type='submit'>Save Data</Button>
                        </>
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className="bg-white mt-8 mb-8 py-8 px-8 shadow sm:rounded-lg sm:px-12 w-full h-full">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                KYC FORM
                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="row mt-4">
                                  <div className="col-6">
                                    <InputField
                                      label='First Name'
                                      type='text'
                                      className="form-control"
                                      placeholder="Name"
                                      error={formState.errors['name']}
                                      registration={register('name')}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <InputField
                                      label='Second Name'
                                      type='text'
                                      className="form-control"
                                      placeholder="Second Name"
                                      error={formState.errors['second_name']}
                                      registration={register('second_name')}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-6">
                                    <InputField
                                      label='Other Name'
                                      type='text'
                                      className="form-control"
                                      placeholder="Other name"
                                      error={formState.errors['other_name']}
                                      registration={register('other_name')}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <InputPhone
                                      label='Phone'
                                      value={newData?.phone}
                                      registration={register('phone')}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-6">
                                    <InputField
                                      label='ID No'
                                      type='text'
                                      className="form-control"
                                      placeholder="Id no"
                                      error={formState.errors['id_no']}
                                      registration={register('id_no')}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <InputField
                                      label='Passport No'
                                      type='text'
                                      className="form-control"
                                      placeholder="Passport No"
                                      error={formState.errors['passport']}
                                      registration={register('passport')}
                                    />
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-6">
                                    <SelectField
                                      label='Choice of Payment'
                                      error={formState.errors['payment']}
                                      registration={register('payment')}
                                      options={paymentOptions}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <SelectField
                                      label='Residential Status'
                                      error={formState.errors['residence']}
                                      registration={register('residence')}
                                      options={residentialOptions}
                                    />
                                  </div>
                                </div>

                                <div className="row mt-4">
                                  <div className="col-6">
                                    <InputField
                                      label='Purchaser Address'
                                      type='text'
                                      className="form-control"
                                      placeholder="Purchaser Address"
                                      error={formState.errors['address_1']}
                                      registration={register('address_1')}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <InputField
                                      label='Postal Address'
                                      type='text'
                                      className='form-control'
                                      placeholder='Postal Address'
                                      error={formState.errors['postal_address']}
                                      registration={register('postal_address')}
                                    />
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-8">
                            <InputField
                              type='date'
                              error={formState.errors['date']}
                              registration={register('date')}
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label="Agreement for Sale Description"
                              type='text'
                              className="form-control"
                              placeholder='in respect Apartment Number 8 on the 8th Floor in Tower B in Phase 1 of the development to be known as “1870 Westlands"'
                              error={formState.errors['agreement_for_sale_text']}
                              registration={register('agreement_for_sale_text')}
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='The Lease Premium payable by the Purchaser'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['lease_text_1']}
                              registration={register('lease_text_1')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='The Lease Premium payable before the execution of this Agreement'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['lease_text_2']}
                              registration={register('lease_text_2')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label=" The Balance Lease Premium payable before the completion date"
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['lease_text_3']}
                              registration={register('lease_text_3')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Legal Fees'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['legal_fees']}
                              registration={register('legal_fees')}
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Office Disbursements Charge'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['office_charge']}
                              registration={register('office_charge')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label="VAT (on the legal fees and office disbursements on the basis of 16% VAT)"
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['vat_charge']}
                              registration={register('vat_charge')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label="Deposit (70% of legal fees including disbursements and VAT) before the execution of Agreement"
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['deposit_charge_1']}
                              registration={register('deposit_charge_1')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label="Balance (30% of legal fees including disbursements and VAT) before the Completion Date"
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['deposit_charge_2']}
                              registration={register('deposit_charge_2')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label="Estimated Stamp duty on the Lease (being 4% of the Lease Premium (subject to valuation by a Government Valuer "
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['stamp_cost_1']}
                              registration={register('stamp_cost_1')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label="Estimated Stamp duty on the Charge being 0.1% of the value of the financed amount (where the Purchaser is financed)."
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['stamp_cost_2']}
                              registration={register('stamp_cost_2')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label="Estimated stamping and registration costs "
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['stamp_cost_3']}
                              registration={register('stamp_cost_3')}
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Estimated Vendor’s Advocates costs for the incorporation of the Phase 1 Management Company'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['vendor_cost_1']}
                              registration={register('vendor_cost_1')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label="Estimated Vendor’s Advocates costs for the incorporation of the Residential Management Company"
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['vendor_cost_2']}
                              registration={register('vendor_cost_2')}
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Estimated Cost of transfer/allotment of one (1) share in the Residential Management Company to the Phase 1 Management Company payable to the Company Secretary (if applicable)'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['vendor_cost_4']}
                              registration={register('vendor_cost_4')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Cost of transfer/allotment of one (1) share in the Phase 1 Management Company to the Company Secretary'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['vendor_cost_5']}
                              registration={register('vendor_cost_5')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label=' Purchaser’s share of the legal fees for undertaking the transfer of the reversionary interest'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['legal_fees_1']}
                              registration={register('legal_fees_1')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Purchaser’s share of the consideration for the reversionary interest in the Phase 1 Land'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['legal_fees_2']}
                              registration={register('legal_fees_2')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Estimated electricity meter deposit and connection charges (subject to actual cost incurred from the service provider)'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['electricity_charge']}
                              registration={register('electricity_charge')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <InputField
                              label=' Estimated disbursements for water infrastructure per unit (subject to actual cost incurred from the service provider)'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['water_charge']}
                              registration={register('water_charge')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='(If georeferenced plans for the Sale Property are required by the Lands Registry), estimated disbursements for preparation and approval of georeferenced plans. This is an estimate and the final costs (which may be lower or higher) will be confirmed.'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['final_costs']}
                              registration={register('final_costs')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Initial Provisional Service Charge'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['initial_charge_1']}
                              registration={register('initial_charge_1')}
                            />
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Initial Service Charge Deposit'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['initial_charge_2']}
                              registration={register('initial_charge_2')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label="Initial Provisional Infrastructure Levy"
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['initial_charge_3']}
                              registration={register('initial_charge_3')}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">

                            <InputField
                              label='Initial Provisional Infrastructure Levy Deposit'
                              type='number'
                              className="form-control"
                              placeholder='0'
                              error={formState.errors['initial_charge_4']}
                              registration={register('initial_charge_4')}
                            />
                          </div>
                        </div>


                      </div>
                    </div>
                  </>
                )}
              </Form>
            </div>

          </div>
        }
      </div>



      {/* <Page pageNumber={1}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="paragraph text-center p-4">Dated as of the day , 20.......</p>
          <div className="paragraph owner-names text-center p-4">
            <span className="bold">
              <input
                className="form-control contract-edit"
                readOnly={inputReadOnly}
                placeholder="MI VIDA THIKA ROAD LIMITED"
                value={editedText.vendor_name}
                onChange={(e) => setEditedText({ ...editedText, vendor_name: e.target.value })}
              />
            </span>
            <span className="bold">
              {' '}
              <input
                className="form-control contract-edit"
                readOnly={inputReadOnly}
                placeholder="(as Vendor)"
                value={editedText.vendor}
                onChange={(e) => setEditedText({ ...editedText, vendor: e.target.value })}
              />
            </span>
            <span>and</span>
            <span className="bold">
              {' '}
              <input
                className="form-control contract-edit"
                readOnly={inputReadOnly}
                placeholder="SHEILA KONYU MUCHEMI"
                value={editedText.purchaser_name}
                onChange={(e) => setEditedText({ ...editedText, purchaser_name: e.target.value })}
              />
            </span>
            <span className="bold">
              <input
                className="form-control contract-edit"
                readOnly={inputReadOnly}
                placeholder="(as Purchaser)"
                value={editedText.purchaser}
                onChange={(e) => setEditedText({ ...editedText, purchaser: e.target.value })}
              />
            </span>
          </div>
          <div className="paragraph owner-names width-max top-space border-t border-b text-center p-4">
            <span className="bold pb-3">AGREEMENT FOR SALE</span>
            <span className="f-14">
              <input
                className="form-control contract-edit"
                readOnly={inputReadOnly}
                placeholder="in respect Apartment Number 8 on the 8th Floor in Tower B in Phase 1 of the
                development to be known as “Mi Vida Garden City”"
                value={editedText.agreement_for_sale_text}
                onChange={(e) =>
                  setEditedText({ ...editedText, agreement_for_sale_text: e.target.value })
                }
                size={70}
              />
            </span>
          </div>
          <div className="company-logo text-center p-4">
            <img className="logo" src={logo} alt="" />
          </div>
          <div className="paragraph owner-names  text-center f-14 pb-3">
            <span>Anjarwalla & Khanna LLP </span>
            <span>ALN House </span>
            <span>Eldama Ravine Close </span>
            <span>Off Eldama Ravine Road </span>
            <span>Westlands </span>
            <span>P.O. Box 200-00606 Sarit Centre </span>
            <span className="underline">Nairobi </span>
          </div>
        </div>
      </Page>
      <Page pageNumber={2}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="paragraph bold f-14">Table of Contents</p>
          <div className="paragraph owner-names table-cnt">
            <p className="f-14 bold d-flex justify-content-between relative">
              <span>Clause </span>
              <span>Page </span>
            </p>
            <p className="f-14 uppercase mb-2 semi-bold d-flex justify-content-between relative dotted-line">
              <span>1 DEFINITIONS AND INTERPRETATION</span>
              <span> 4 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>2 AGREEMENT FOR SALE</span>
              <span> 9 </span>
            </p>
            <p className="f-14 mb-2  uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>3 GRANT OF THE LEASE</span>
              <span> 9 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>4 THE LEASE PREMIUM</span>
              <span> 11 </span>
            </p>
            <p className="f-14 mb-2 uppercasev semi-bold d-flex justify-content-between relative dotted-line">
              <span>5 INTEREST ON LATE PAYMENTS</span>
              <span> 12 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>6 TERMINATION OF THIS AGREEMENT</span>
              <span> 12 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>7 DISCLAIMER & DEFECTS LIABILITY</span>
              <span> 13 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>8 GENERAL PROVISIONS</span>
              <span> 14 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>9 DISPUTES</span>
              <span> 17 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>10 NOTICES</span>
              <span> 18 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>11 EXECUTORY AGREEMENT</span>
              <span> 19 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>Schedule 1 </span>
              <span> 20 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>Schedule 2 FORM OF LEASE </span>
              <span> 24 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>Schedule 3 SALE PROPERTY PLAN </span>
              <span> 25 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>Schedule 4 RESIDENTIAL DEVELOPMENT PLAN </span>
              <span> 26 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>Schedule 5 PLANS </span>
              <span> 27 </span>
            </p>
            <p className="f-14 mb-2 uppercase semi-bold d-flex justify-content-between relative dotted-line">
              <span>Schedule 6 LSK CONDITIONS </span>
              <span> 28 </span>
            </p>
          </div>
        </div>
      </Page>
      <Page pageNumber={3}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="paragraph semi-bold f-14">
            <span className="bold">
              <input
                className="form-control contract-edit"
                readOnly={inputReadOnly}
                placeholder="the agreement is made as of the 20"
                value={editedText.sales_date}
                onChange={(e) => setEditedText({ ...editedText, sales_date: e.target.value })}
                size={50}
              />
            </span>{' '}
          </p>
          <span className="bold f-14 pb-3">BETWEEN</span>
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">(1)</span>
                <span>
                  <span className="bold">MI VIDA THIKA ROAD LIMITED</span> (Company Number
                  PVT-9XUJ37Z), a private company incorporated with limited liability in the
                  Republic of Kenya for the purposes hereof of Post Office Box Number 43233-00100,
                  General Mathenge Drive, Westlands, Nairobi, Kenya (hereinafter called the Vendor
                  which expression shall, where the context so requires, include the Vendor's
                  successors in title and assigns); and
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">(2)</span>
                <span>
                  The person(s) identified in paragraph 2.1 of Schedule 1 (hereinafter called the{' '}
                  <span className="bold">Purchaser</span>).
                </span>
              </span>
            </li>
            <span className="bold f-14 pb-3">WHEREAS</span>
            <li>
              <span className="inner-list">
                <span className="agree-number">(A)</span>
                <span>
                  Kasarani Investment Holdings Limited (company Number C.127008) a limited liability
                  company incorporated in Mauritius (<span className="bold">KIHL</span>) is the
                  registered owner of the Land.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">(B)</span>
                <span>
                  The Vendor is registered as the proprietor of the Phase 1 Land (as hereinafter
                  defined) and has constructed the Phase 1 Development (as hereinafter defined) on
                  the Phase 1 Land.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">(C)</span>
                <span>
                  The Sale Property (as hereinafter defined) forms part of the Phase 1 Development.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">(D)</span>
                <span>
                  The Vendor has agreed to sell the Sale Property to the Purchaser and the Purchaser
                  has agreed to take up the Lease upon the terms and conditions hereinafter
                  contained.
                </span>
              </span>
            </li>
            <li className="agreement-inner-list">
              <span className="inner-list">
                <span className="agree-number">(E)</span>
                <span>The Vendor will procure:</span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> (i)</span>
                    <span>
                      (if only the Phase 1 Development and no other portion of the Residential
                      Development is developed by the Vendor), the formation of a company (the{' '}
                      <span className="bold">Phase 1 Management Company</span>) for purposes of:
                    </span>
                  </span>
                  <ol className="agreement-list inner f-14">
                    <li>
                      <span className="inner-list">
                        <span className="agree-number"> (a)</span>
                        <span>
                          managing the Tower Common Parts and the Phase 1 Common Parts; and
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="inner-list">
                        <span className="agree-number"> (b)</span>
                        <span>holding the reversionary interest in the Phase 1 Land; or</span>
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> (ii)</span>
                    <span>
                      (if any other phase or portion of the Residential Development (in addition to
                      the Phase 1 Development) is developed by the Vendor):
                    </span>
                  </span>
                  <ol className="agreement-list inner f-14">
                    <li>
                      <span className="inner-list">
                        <span className="agree-number"> (a)</span>
                        <span>
                          the formation of the Phase 1 Management Company for the purposes of
                          managing the Tower Common Parts; and
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="inner-list">
                        <span className="agree-number"> (b)</span>
                        <span>
                          the formation of a company (the{' '}
                          <span className="bold">Residential Management Company </span>) for
                          purposes of:
                        </span>
                      </span>
                      <ol className="agreement-list inner f-14">
                        <li>
                          <span className="inner-list">
                            <span className="agree-number"> i.</span>
                            <span>
                              managing the Common Parts (excluding the Tower Common Parts to be
                              managed by the Phase 1 Management Company); and
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className="inner-list">
                            <span className="agree-number">ii.</span>
                            <span>
                              holding the reversionary interest in the Phase 1 Land, with the
                              intention being that the Phase 1 Management Company shall hold shares
                              in the Residential Management Company on behalf of the owners of the
                              Apartments in the Phase 1 Development on registration of all leases
                              for all the Apartments.
                            </span>
                          </span>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">(F)</span>
                <span>
                  (If required), the Phase 1 Management Company and the Residential Management
                  Company will enter into appropriate agreements with each other in relation to the
                  management of the Phase 1 Development and the Residential Development (or such
                  portion thereof). The arrangements under recital D and this recital E shall
                  hereinafter be referred to as the{' '}
                  <span className="bold"> Management Structure</span>).
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={4}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">(G)</span>
                <span>
                  Upon completion of the grant and registration of the Lease and the leases for all
                  the other purchasers of the Apartments erected on the Phase 1 Land there shall be
                  allotted or transferred to the Purchaser one (1) ordinary share in the share
                  capital of the Phase 1 Management Company.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">(H)</span>
                <span>
                  Unless prevented by circumstances of force majeure or a change in law, the Vendor
                  will after the grant and registration of all leases in respect of all the
                  Apartments comprised in the Residential Development ensure that the Vendor’s
                  reversionary interest in the Phase 1 Land shall be transferred at a nominal
                  consideration to either the Phase 1 Management Company or the Residential
                  Management Company or such other company as may be nominated by the Vendor (as
                  determined by the Vendor at its sole discretion). All costs and expenses incurred
                  by the Vendor in the transfer of the Vendor’s reversionary interest as aforesaid,
                  shall be borne by all the purchasers of the Apartments in the Phase 1 Development
                  equally.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">(I)</span>
                <span>
                  The purpose of this Agreement is to (inter alia) record the agreements reached
                  between the Vendor and the Purchaser in relation to the purchase of the Sale
                  Property, payment of the Lease Premium and allotment or transfer of shares in the
                  Phase 1 Management Company.
                </span>
              </span>
            </li>
          </ol>
          <span className="bold f-14 pb-3">NOW IT IS AGREED as follows</span>
          <ol className="agreement-list f-14 mb-0">
            <li>
              <span className="inner-list">
                <span className="agree-number bold">1</span>
                <span className="bold">DEFINITIONS AND INTERPRETATION</span>
              </span>
            </li>
          </ol>
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">1.1</span>
                <span className="">
                  <span className="list-block">
                    In this Agreement (including the recitals above and Schedules) except where the
                    context otherwise requires the following words and expressions shall have the
                    following meanings:
                  </span>
                  <span className="list-block">
                    <span className="bold">Agreed Rate of Interest </span>means the per annum rate
                    which is seven percent (7%) above the Central Bank Rate stipulated by the
                    Monetary Policy Committee of the Central Bank of Kenya{' '}
                    <span className="bold">(CBK)</span> as may be published from time to time{' '}
                    <span className="bold">(CBR)</span> or any such similar rate published by the
                    CBK and in the event that the CBK does not publish the CBR or such similar rate,
                    seven percent (7%) above the 182-day Treasury Bill rate;
                  </span>
                  <span className="list-block">
                    <span className="bold">Apartments </span>means an aggregate of approximately six
                    hundred and sixty three (663) residential apartments (or such other number to be
                    determined by the Vendor in its sole discretion) proposed to be constructed in
                    the Residential Development (including the Phase 1 Development) constructed in
                    the Residential Development (including the Phase 1 Development) and the
                    expression <span className="bold">Apartment </span> shall be construed
                    accordingly;
                  </span>
                  <span className="list-block">
                    <span className="bold">Applicable Laws </span>means together the Land Act (Act
                    No. 6 of 2012, Laws of Kenya) and the Land Registration Act (Act No. 3 of 2012,
                    Laws of Kenya) and the Sectional Properties Act, 2020 and shall include any
                    subsidiary legislation, rules and regulations promulgated thereunder or any
                    other legislation rules and regulations affecting the Sale Property, the
                    Property or the Residential Development and any practice directions issued
                    pursuant to the Land Act and the Land Registration Act and the Sectional
                    Properties Act, 2020;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Approved Documents </span>means the plans (including the
                    Sale Property Plan) relating to the construction of the Sale Property, the Phase
                    1 Development or the Residential Development (as the case may be), prepared by
                    the Vendor and/or the Architect as the same may be supplemented, replaced, added
                    to or revised by the Vendor from time to time as provided herein;
                  </span>

                  <span className="list-block">
                    <span className="bold"> Architect </span>means such person as the Vendor may
                    from time to time appoint as the architect for the Phase 1 Development;
                  </span>
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={5}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14 pl-left">
            <li>
              <span className="inner-list">
                <span className="">
                  <span className="list-block">
                    <span className="bold">Architect’s Certificate </span>means the certificate
                    dated 22 July 2022 issued by the Architect of practical completion of the Sale
                    Property and partial or sectoral completion of the Tower in which the Sale
                    Property is situated and so much of the Phase 1 Development as gives, in the
                    Architect's opinion, sufficient access to the Sale Property for the purposes of
                    the Purchaser being able to occupy the Sale Property;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Balance </span>
                    has the meaning ascribed to it in paragraph 3.1.2 of Schedule 1;
                  </span>
                  <span className="list-block">
                    <span className="bold">Business Day </span> means any day (other than Saturday,
                    Sunday, national day or gazetted public holiday in Kenya) on which banking
                    institutions in Kenya are generally open for the conduct of banking business;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Car Park Spaces </span> means the number car parking
                    spaces specified in paragraph 6.1 of Schedule 1 situate on a portion of the
                    Phase 1 Development and to be allocated to the Purchaser in accordance with this
                    Agreement;
                  </span>

                  <span className="list-block">
                    <span className="bold"> Charges </span> means together the Initial Provisional
                    Service Charge, the Initial Provisional Infrastructure Levy, the Initial
                    Infrastructure Levy Deposit and the Initial Service Charge Deposit and the sums
                    provided under paragraph 9.11 and 9.12 of Schedule 1 in respect of water and
                    electricity meter installation costs payable by the Purchaser to the Vendor and
                    any other charges payable by the Purchaser to the Vendor under this Agreement;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Common Parts </span> means together the Phase 1 Common
                    Parts, the Tower Common Parts and such other common parts and, other
                    improvements that may be made, from time to time, at the discretion of the
                    Vendor, the Phase 1 Management Company and/or the Residential Management Company
                    on the Phase 1 Land or the Property (or any portion thereof) and which are to be
                    utilised for the common use of only the purchasers or occupiers of the Phase 1
                    Development and if applicable the Residential Development (or any portion
                    thereof);
                  </span>
                  <span className="list-block">
                    <span className="bold">Competent Authority</span> means the Government of the
                    Republic of Kenya, the National Land Commission, the relevant county government,
                    agencies, state corporations and other bodies having statutory or regulatory
                    competence to promulgate rules and regulations having the force of law touching
                    and concerning the Property and/or the Sale Property and or the Residential
                    Development and the transactions and matters contemplated and referred to in
                    this Agreement;
                  </span>
                  <span className="list-block">
                    <span className="bold">Completion</span> means the completion of the sale and
                    purchase of the Sale Property as provided for in this Agreement and the term{' '}
                    <span className="bold">Complete </span> shall be construed accordingly;
                  </span>
                  <span className="list-block">
                    <span className="bold">Completion Date</span> means 25 December 2022 and being
                    the date on which Completion shall take place or such other date as the parties
                    may agree in writing but if such day is not a Business Day then on the
                    immediately succeeding Business Day or such other date as the parties may agree
                    in writing;
                  </span>
                  <span className="list-block">
                    <span className="bold">Deposit </span> means the sum referred to in paragraph
                    3.1.1 of Schedule 1;
                  </span>
                  <span className="list-block">
                    <span className="bold">Estate </span> means the mixed-use development together
                    with related amenities and facilities to be constructed on a portion of the
                    Land;
                  </span>
                  <span className="list-block">
                    <span className="bold">Estate Infrastructure </span> means all the
                    infrastructure to be developed on the Land (including the Property) or on the
                    adjoining parcels of land which are to be utilised for the common use of all or
                    some of the lessees, occupiers and lawful visitors of the Land and/or the
                    adjoining parcels or persons expressly or by implication authorised by them.
                    Nothing contained in this definition shall be construed as imposing any
                    obligation on the Vendor to provide any such conveniences as are specifically
                    mentioned above, unless expressly stipulated otherwise in this Agreement or the
                    Lease;
                  </span>
                  <span className="list-block">
                    <span className="bold">Initial Infrastructure Levy Deposit </span> shall have
                    the meaning ascribed to it in the Lease and being the sum set out in paragraph
                    13 of Schedule 1;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Infrastructure Levy </span>shall have the meaning
                    ascribed to it in the Lease;
                  </span>
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={6}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14 pl-left">
            <li>
              <span className="inner-list">
                <span className="">
                  <span className="list-block">
                    <span className="bold">Initial Provisional Infrastructure Levy </span> shall
                    have the meaning ascribed to it in the Lease and being the sum set out in
                    paragraph 12 of Schedule 1;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Initial Provisional Service Charge </span>
                    shall have the meaning ascribed to it in the Lease and being the sum set out in
                    paragraph 10 of Schedule 1;
                  </span>
                  <span className="list-block">
                    <span className="bold">Initial Service Charge Deposit </span> shall have the
                    meaning ascribed to it in the Lease and being the sum set out in paragraph 11 of
                    Schedule 1;
                  </span>
                  <span className="list-block">
                    <span className="bold">Kenya Shillings </span> or{' '}
                    <span className="bold">KES</span> or <span className="bold">KShs</span> . means
                    the lawful currency for the time being of the Republic of Kenya;
                  </span>

                  <span className="list-block">
                    <span className="bold"> KIHL </span> has the meaning ascribed to it in Recital
                    (A);
                  </span>
                  <span className="list-block">
                    <span className="bold">Land </span> means{' '}
                    <span className="bold"> ALL THAT </span> parcel of land, situate in the City of
                    Nairobi in the Nairobi Area of the Republic of Kenya containing by measurement
                    five decimal nine two six (5.926) hectares or thereabouts and being the property
                    known as Land Reference Number 10119/7 (original Number 10119/6/2) which said
                    piece of land with the dimensions abuttals and boundaries thereof is delineated
                    on Land Survey Plan Number 419577 deposited in the Survey Records Office at
                    Nairobi aforesaid held for a term of ninety nine (99) years from 1 July 2017
                    subject however to the prevailing laws and regulations, the special conditions,
                    encumbrances and other matters specified in the Memorandum endorsed on the
                    Certificate of Title registered as Number I.R. 210045/1;
                  </span>
                  <span className="list-block">
                    <span className="bold">Lease</span> means a sub-lease in respect of the Sale
                    Property to be granted by the Vendor to the Purchaser with effect from the
                    sub-lease commencement date (which shall be the Completion Date) for the yearly
                    rent of a peppercorn (if demanded) the lease being in or substantially in the
                    form set out in Schedule 2 (if applicable);
                  </span>
                  <span className="list-block">
                    <span className="bold"> Lease Premium</span> means the sum set out in paragraph
                    3 of Schedule 1 and payable pursuant to the provisions of clause 4.1;
                  </span>
                  <span className="list-block">
                    <span className="bold"> LSK Conditions</span> means the Law Society of Kenya
                    Conditions of Sale (1989 Edition) and annexed as Schedule 6;
                  </span>
                  <span className="list-block">
                    <span className="bold"> New Purchaser </span> means any person to which the
                    Purchaser as beneficial owner wishes to assign the benefit and transfer the
                    obligations of the Purchaser under this Agreement after the date of this
                    Agreement but prior to the completion;
                  </span>
                  <span className="list-block">
                    <span className="bold">Phase 1 Common Parts </span> means the common parts in
                    the Phase 1 Land (excluding the Tower Common Parts), which are not sold or let
                    or intended to be sold or let by the Vendor, including but not limited to
                    pedestrian walkways, concourses, gardens, parking areas, lobbies on each of the
                    parking levels, public and natural open spaces, parks and squares and all other
                    facilities, utilities and other improvements in the Phase 1 Development which
                    are from time to time during the term of the Lease provided by the Vendor for
                    common use by the purchaser or other occupiers of Phase 1 Development;
                  </span>
                  <span className="list-block">
                    <span className="bold">Phase 1 Development </span> means two hundred and
                    twenty-one (221) Apartments (constructed on the Phase 1 Land together with
                    related amenities and facilities as more particularly identified in the Approved
                    Documents;
                  </span>
                  <span className="list-block">
                    <span className="bold">Phase 1 Development Plan</span> means the plan(s) annexed
                    hereto in Schedule 5 and marked “Phase 1 Development”;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Phase 1 Land </span>means that portion of the Property
                    containing by measurement approximately one decimal eight nine (1.89) acres or
                    thereabouts with the dimensions, abuttals and boundaries thereof more
                    particularly delineated and described on the Property Plan;
                  </span>
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={7}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14 pl-left">
            <li>
              <span className="inner-list">
                <span className="">
                  <span className="list-block">
                    <span className="bold">Phase 1 Land Lease </span> means the lease in respect of
                    the Phase 1 Land to be entered into between KIHL and the Vendor for the
                    unexpired term of the head lease less the last fourteen (14) days;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Phase 1 Management Company </span>
                    has the meaning ascribed to it under Recital (E) (ii);
                  </span>
                  <span className="list-block">
                    <span className="bold">Possession Date </span>
                    means the date when the Purchaser is entitled to take possession of the Sale
                    Property and being the date (as determined by the Vendor or the Vendor’s
                    Advocates) when all of the following conditions have been satisfied by the
                    Purchaser to the satisfaction of the Vendor:
                  </span>
                  <ol className="agreement-list inner f-14">
                    <li>
                      <span className="inner-list">
                        <span className="agree-number">a.</span>
                        <span>
                          the Lease Premium and all other monies required to be paid by the
                          Purchaser to the Vendor or the Vendor’s Advocates pursuant to the terms of
                          this Agreement are unconditionally paid and released to the Vendor or the
                          Vendor’s Advocates (as the case may be) as certified by the Vendor or the
                          Vendor’s Advocates;
                        </span>
                      </span>
                      <span className="inner-list">
                        <span className="agree-number">b.</span>
                        <span>the Lease has been duly executed by the parties; and</span>
                      </span>
                      <span className="inner-list">
                        <span className="agree-number">c.</span>
                        <span>
                          all other obligations of the Purchaser under this Agreement have been
                          satisfied.
                        </span>
                      </span>
                    </li>
                  </ol>
                  <span className="list-block">
                    <span className="bold">Property </span> means that portion of the Land
                    containing by measurement approximately four decimal four nine (4.49) acres or
                    thereabouts with the dimensions, abuttals and boundaries thereof more
                    particularly delineated and described on the Property Plan;
                  </span>

                  <span className="list-block">
                    <span className="bold"> Property Plan </span> means the plan annexed hereto in
                    Schedule 5 and marked “Property Plan”;
                  </span>
                  <span className="list-block">
                    <span className="bold">Residential Development </span> means the proposed
                    multi-phased residential development together with related amenities and
                    facilities to be constructed on the Property at the sole discretion of the
                    Vendor and to be known as “Mi Vida Garden City” (or such other name as may be
                    determined by the Vendor) as more particularly identified in the Residential
                    Development Plan and includes the Phase 1 Development. Nothing contained in this
                    definition shall be construed as imposing any obligation on the Vendor to
                    undertake any other phase of the residential development other than the Phase 1
                    Development, unless expressly stipulated otherwise in this Agreement or the
                    Lease;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Residential Development Plan</span> means the
                    development plan annexed hereto in Schedule 4;
                  </span>
                  <span className="list-block">
                    <span className="bold">Residential Management Company </span> has the meaning
                    ascribed to it in Recital (E) (ii);
                  </span>
                  <span className="list-block">
                    <span className="bold"> Restrictions</span> means all notices, orders,
                    resolutions, demands, proposals, requirements, regulations, restrictions,
                    agreements, directions or other matters affecting Residential Development or any
                    portion thereof or the use of the Residential Development or any portion thereof
                    promulgated or made by any Competent Authority or person whether or not having
                    the force of law;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Sale Property </span> has the meaning ascribed to it in
                    Paragraph 1 of Schedule 1;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Sale Property Plan </span> means the plan annexed hereto
                    in Schedule 3;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Service Charge</span> shall have the meaning ascribed to
                    it in the Lease;
                  </span>
                  <span className="list-block">
                    <span className="bold">Tower Common Parts</span> means the common parts in each
                    Tower including but not limited to staircases, parking areas, corridors, lifts,
                    passageways, rooftop clothes drying areas, foyer areas which are from time to
                    time during the term of the Lease provided by the Vendor for common use by the
                    purchaser or other occupiers of the Tower;
                  </span>
                  <span className="list-block">
                    <span className="bold"> Towers </span> means the various Apartment building
                    blocks or complexes constructed on the Phase 1 Land as delineated on the Phase 1
                    Development Plan and for purposes of identification, such towers in the said
                    plan are outlined in blue and the term <span className="bold"> Tower </span>{' '}
                    shall be construed accordingly; and
                  </span>
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={8}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14 pl-left mb-0">
            <li>
              <span className="inner-list">
                <span className="list-block">
                  <span className="bold"> Vendor's Advocates </span> means Anjarwalla & Khanna LLP
                  of ALN House, Eldama Ravine Close, Off Eldama Ravine Road, Westlands P.O. Box
                  200-00606, Sarit Centre, Nairobi.
                </span>
              </span>
            </li>
          </ol>
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">1.2</span>
                <span>
                  In this Agreement (including the recitals and the Schedules hereto), unless the
                  context otherwise requires, reference to:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 1.2.1</span>
                    <span>
                      words denoting the singular number only shall include the plural number also
                      and vice versa and words importing the masculine gender include the feminine
                      gender and neuter and vice versa;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.2</span>
                    <span>
                      sub-clauses, clauses, Sections and the Schedules shall be construed as
                      references to sub-clauses, clauses and sections of and the schedules to this
                      Agreement;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.3</span>
                    <span>
                      the expression <span className="bold">person</span> shall include any legal or
                      natural person, partnership, trust, company, joint venture, agency, government
                      or local authority department or other body (whether corporate or
                      unincorporate);
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.4</span>
                    <span>
                      any statute or any provision of any statute shall be deemed to refer to any
                      statutory modification or re-enactment thereof and to any statutory
                      instrument, order or regulation made thereunder or under any such
                      re-enactment;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.5</span>
                    <span>
                      the <span className="bold">Vendor</span> shall include any person to whom the
                      Vendor's interest in the Property (or any part thereof) is transferred or
                      assigned;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.6</span>
                    <span>
                      indemnifying any person against any circumstance includes indemnifying and
                      keeping him harmless from all actions, claims and proceedings from time to
                      time made against that person and all loss or damage and all payments, costs
                      and expenses made or incurred by that person as a consequence of or which
                      would not have arisen but for that circumstance;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.7</span>
                    <span>
                      <span className="bold">costs </span> shall be deemed to include, in addition,
                      references to any value added tax or similar tax charged or chargeable in
                      respect thereof;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.8</span>
                    <span>
                      a document <span className="bold">in the agreed terms </span> or in
                      <span className="bold">agreed form </span> shall mean in the form agreed by or
                      on behalf of the parties and initialled by or on their behalf;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.9</span>
                    <span>
                      a <span className="bold">party </span> or the{' '}
                      <span className="bold">parties </span> shall mean a party or the parties to
                      this Agreement;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.10</span>
                    <span>
                      the <span className="bold"> Purchaser</span> includes the Purchaser’s personal
                      representatives and permitted assigns if the Purchaser is a natural person and
                      the Purchaser’s successors in title and permitted assigns if the Purchaser is
                      a body corporate;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.11</span>
                    <span>
                      the word <span className="bold"> tax </span> shall be construed so as to
                      include any tax, levy, impost, assessment, duty or other charge of a similar
                      nature (including, without limitation, capital gains tax value added tax,
                      stamp duty and any penalty or interest payable in connection with any failure
                      to pay or any delay in paying any of the same) and{' '}
                      <span className="bold"> taxation</span> shall be construed accordingly and the
                      expression <span className="bold"> competent taxing authority </span> means,
                      in respect of any state or administrative division thereof, any governmental
                      authority, monetary agency or central bank having power to collect or levy
                      taxes;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.12</span>
                    <span>
                      the expression <span className="bold"> registration </span> means the due and
                      effective registration of the Lease in favour of the Purchaser and/or the
                      Purchaser’s nominee at the relevant lands registry in accordance with the
                      Lands Laws; and
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">1.2.13</span>
                    <span>
                      the expression <span className="bold">month</span> means a calendar month.
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">1.3</span>
                <span>
                  Headings to Sections are for convenience only and shall not affect the
                  construction or interpretation of this Agreement.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={9}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">1.4</span>
                <span>
                  In this Agreement any reference to any document (including this Agreement) means
                  that document as is supplemented, amended or varied from time to time in
                  accordance with the terms of that document and, if applicable, hereof.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">1.5</span>
                <span>
                  If the <span className="bold">Purchaser</span> shall consist of two or more
                  persons such expression throughout means and includes such two or more persons and
                  each of them and shall, so far as the context so requires, be construed as well in
                  the plural as in the singular and all covenants, agreements and undertakings
                  herein expressed or implied shall be on the part of the Purchaser joint and
                  several.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">1.6</span>
                <span>
                  For the purposes of this Agreement, if a definition imposes substantive rights on
                  a party to this Agreement such rights and obligations shall be given effect to and
                  shall be enforceable notwithstanding that they are contained in a definition.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">1.7</span>
                <span>
                  Where any term is defined within any particular clause or sub-clause in this
                  Agreement the term so defined, unless it is expressly stated in the clause or
                  sub-clause in question that the term so defined has limited application to the
                  relevant clause or sub-clause, shall bear the meaning ascribed to it wherever
                  utilised in this Agreement notwithstanding that that term has not been defined
                  expressly in Section 1 (Definitions and Interpretation).
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">2</span>
                <span className="bold">AGREEMENT FOR SALE</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">2.1</span>
                <span className="">
                  The Vendor hereby agrees to sell and the Purchaser hereby agrees to purchase the
                  Sale Property on the terms and conditions set out in this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">2.2</span>
                <span className="">
                  The sale and purchase of the Sale Property is subject to the LSK Conditions which
                  shall be deemed incorporated herein in extenso save in so far as the LSK
                  Conditions are not consistent with the provisions of this Agreement or are varied
                  or excluded by the terms of this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">2.3</span>
                <span className="">The interest sold is leasehold.</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">3</span>
                <span className="bold">GRANT OF THE LEASE</span>
              </span>
            </li>

            <li>
              <span className="inner-list">
                <span className="agree-number">3.1</span>
                <span className="">The Vendor shall:</span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 3.1.1</span>
                    <span>
                      grant the Lease for the Lease Premium and the Purchaser shall accept the Lease
                      at the rent (if any) and for a term specified in the Lease (without amendment
                      or variation) which for the avoidance of doubt shall commence on the
                      Completion Date and subject to all the terms, covenants and conditions
                      contained in the Lease. The Lease shall be in or substantially in the form set
                      out in Schedule 2. The Vendor’s Advocates are hereby irrevocably authorized by
                      the Vendor and the Purchaser to make such alterations, modifications and
                      adaptations necessary to the Agreement and the Lease (in the reasonable
                      opinion of the Vendor’s Advocates) to ensure that the Agreement and the Lease
                      are in conformity with the Applicable Laws and the Management Structure or a
                      directive of a Competent Authority. The Purchaser shall execute two (2)
                      counterparts of the Lease and deliver the duly executed Lease to Vendor’s
                      Advocates within seven (7) days from the date of receipt of the Lease;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.1.2</span>
                    <span>
                      grant a licence in respect of the Car Park Spaces in accordance with the terms
                      and conditions stipulated in the Lease; and
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.1.3</span>
                    <span>
                      (upon completion of the grant and registration of the Lease and the leases for
                      all other purchasers of the Apartments erected on the Phase 1 Land) procure
                      the transfer or allotment to the Purchaser of that number of ordinary shares
                      in the capital of the Phase 1 Management Company as is referred to in
                      paragraph 7.1 of Schedule 1.
                    </span>
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={10}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">3.2</span>
                <span>
                  By no later than the Completion Date, the Purchaser shall deliver to the Vendor or
                  the Vendor’s Advocates payment advice slips confirming payment of the sums set out
                  in paragraphs 3, 9, 10, 11, 12 and 13 of Schedule 1 to the Vendor and the Vendor’s
                  Advocates (as the case may be) in accordance with this Agreement. The Vendor shall
                  be entitled to verify all confirmations of the payment advice slips and further
                  confirm receipt of payment in cleared funds.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.3</span>
                <span>
                  Subject to the fulfilment of the conditions specified in clause 3.2, above, the
                  Vendor’s Advocates will forward the Lease to the Purchaser or the Purchaser’s
                  advocates for execution by the Purchaser and the Purchaser or the Purchaser’s
                  advocates will deliver to the Vendor’s Advocates within seven (7) days of receipt
                  of the Lease the following documents:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 3.3.1</span>
                    <span>the Lease duly executed by the Purchaser; and</span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.3.2</span>
                    <span>
                      if the Purchaser is an individual, two (2) coloured passport size photographs
                      of the Purchaser, or if the Purchaser is a company, two (2) coloured passport
                      size photographs each of the two (2) directors or the director and the company
                      secretary or authorised signatories who have executed the Lease;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.3.3</span>
                    <span>
                      the charge (if applicable) in respect of the Sale Property (if any) duly
                      executed by the Purchaser and the Purchaser’s financier; and
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.3.4</span>
                    <span>
                      any other document as may be reasonably required by the Vendor to register the
                      Lease and the charge (if applicable) at the Lands Registry and to give effect
                      to the provisions of this Agreement.
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.4</span>
                <span>
                  The Lease will be executed by the Vendor subject to the following having occurred:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 3.4.1</span>
                    <span>the Purchaser having executed the Lease; and</span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.4.2</span>
                    <span>
                      the Purchaser having satisfied all its payments obligations and other
                      obligations under this Agreement and the Lease Premium having been
                      unconditionally released to the Vendor.
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.5</span>
                <span>
                  The Vendor’s Advocates are hereby authorised by the Vendor and the Purchaser to
                  complete any blanks in the Lease and (if any), including without limitation, to
                  change the Lease Premium (if such change is agreed between the Vendor and the
                  Purchaser in writing) and complete the First Schedule to the Lease. The Vendor has
                  set out in paragraphs 9.4, 9.11, 9.12, 10, 11, 12 and 13 of Schedule 1, the
                  amounts in respect of the disbursements for stamping, valuation and registration,
                  water infrastructure, electricity meter deposit and connection charges, the
                  Initial Provisional Service Charge, the Initial Service Charge Deposit, the
                  Initial Provisional Infrastructure Levy, the Initial Infrastructure Levy Deposit
                  and the Utility Deposit on the basis of the Lease being granted at the date hereof
                  provided that the Purchaser acknowledges and agrees that the amounts specified
                  under paragraphs 9.4, 9.11, 9.12, 10, 11, 12 and 13 of Schedule 1 are merely
                  approximate amounts and are subject to change at the time of the grant of the
                  Lease as a result of inflation or otherwise or for any other reason including on
                  account of the changes contemplated under clause 3.1.1 and 8.7. The Purchaser
                  acknowledges and confirms that the Charges shall be paid by the Purchaser on or
                  before the Completion Date.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.6</span>
                <span className="">
                  The Purchaser will contribute towards the Vendor’s Advocates legal fees for
                  drawing and negotiating this Agreement and the Lease and all stamp duties and
                  registration costs in connection with the Lease) and all other applicable
                  disbursements in connection with the creation and registration of the Lease shall
                  be payable by the Purchaser on or before the Completion Date in accordance with
                  paragraph 9 of Schedule 1. The Purchaser acknowledges that the costs set out in
                  paragraph 9 of Schedule 1 would be, if Completion occurred at the date hereof, the
                  amounts specified in Schedule 1, but may by the Completion Date, have increased at
                  least in line with inflation or for any other reason including on account of the
                  changes contemplated under clause 3.1.1 and 8.7.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={11}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">3.7</span>
                <span>
                  Upon fulfilment of the provisions of clauses 3.2, 3.3, 3.5 and 4.1 by the
                  Purchaser, the Vendor’s Advocate shall undertake the registration of the Lease and
                  the charge (if applicable). If at the Completion Date or thereafter, the costs in
                  relation to stamp duty, registration charges and disbursements shall increase, the
                  Purchaser shall be required to make the requisite additional payments to cover the
                  increase in costs.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.8</span>
                <span>
                  Upon registration of the Lease at the Lands Registry, the Vendor’s Advocates will
                  deliver to the Purchaser or the Purchaser’s Advocates (as the case may be), the
                  following documents:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 3.8.1</span>
                    <span>the original registered Lease;</span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.8.2</span>
                    <span>
                      a copy of the KRA iTax generated e-slip processed by the Purchaser together
                      with a copy of the tax payment acknowledgement slip in respect of stamp duty
                      paid on the Lease; and
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.8.3</span>
                    <span>
                      a copy of the registered partial discharge of charge in respect of the Sale
                      Property (if applicable).
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.9</span>
                <span>
                  The Purchaser acknowledges and agrees that the Sale Property will be sold subject
                  to all Restrictions affecting the Land, the Residential Development, the Property,
                  the Phase 1 Land and/or the Sale Property as at the Completion Date.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.10</span>
                <span>
                  The Purchaser hereby agrees and confirms that the possession of the Sale Property
                  shall only be granted to the Purchaser on the Possession Date. If the Purchaser
                  fails to take possession of the Sale Property on the Possession Date in accordance
                  with this clause 3.10, the Vendor may send the keys for the Sale Property to the
                  Purchaser by way of registered post to the Purchaser’s last known address as
                  indicated hereunder. For the avoidance of doubt, notwithstanding that physical
                  possession of the Sale Property shall take place on the Possession Date, all risks
                  in the Sale Property shall pass to the Purchaser on the Completion Date and the
                  Vendor shall not be responsible and shall not be liable for any direct or indirect
                  loss of any kind whatsoever, howsoever and by whomsoever caused from the
                  Completion Date. For the avoidance of doubt, Service Charge will become applicable
                  and will accrue and become payable from the time the Purchaser is entitled to take
                  vacant possession of the Sale Property on the terms of this Agreement, whether or
                  not the Purchaser shall take possession at that time.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.11</span>
                <span className="">
                  Notwithstanding the grant of the Lease, the Vendor shall have the right with
                  servants, agents, workmen and independent contractors and their plant, machinery
                  and materials to enter the Sale Property upon issuance of at least forty eight
                  (48) hours prior notice (save in cases of emergency when no notice shall be
                  required to be given) for the purpose of carrying out such works as are referred
                  to in clause 7.3.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">4</span>
                <span className="bold">THE LEASE PREMIUM</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">4.1</span>
                <span className="">
                  The Lease Premium shall be paid by the Purchaser to the Vendor in accordance with
                  paragraph 3 of Schedule 1 or otherwise as the Vendor may direct in writing. The
                  Purchaser hereby confirms that all payments made under this Agreement with respect
                  to the Lease Premium to the Vendor or for the account of the Vendor shall be
                  unconditionally released to the Vendor.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={12}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">4.2</span>
                <span>
                  It is hereby agreed that the Vendor shall upon the execution of this Agreement and
                  subject to receipt of the Deposit by the Vendor apply the Deposit towards partial
                  satisfaction of the Purchaser’s obligation hereunder to pay the Lease Premium.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">4.3</span>
                <span>
                  The Purchaser agrees that payments due under Clause 4.1 shall be paid by EFT or
                  RTGS (or, in the case of an international transfer, by SWIFT) transfer to such
                  bank account as the Vendor shall in writing notify the Purchaser or in such other
                  manner as the Vendor may from time to time reasonably require. Immediately upon
                  the Purchaser effecting any payment by EFT or RTGS (or, in the case of an
                  international transfer, by SWIFT) transfer pursuant to the terms of this
                  Agreement, the Purchaser shall deliver to the Vendor proof of such payment (such
                  as a remittance advice by the Purchaser’s bankers) provided that the Vendor shall
                  be entitled to verify such proof of payment and further confirm receipt of such
                  payment in cleared funds.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">4.4</span>
                <span>
                  Time shall be of the essence in respect of all the Purchaser’s obligations
                  hereunder.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">5</span>
                <span className="bold">INTEREST ON LATE PAYMENTS</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">5.1</span>
                <span>
                  If the Purchaser is in breach of the Purchaser’s payment obligations under any
                  provision hereof, then at the Vendor’s election:
                </span>
              </span>

              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number">5.1.1</span>
                    <span>
                      {' '}
                      the Vendor may (in its discretion) grant the Purchaser additional time (the
                      <span className="bold"> Additional Time</span>) to effect payment in which
                      event the Purchaser shall pay interest at the Agreed Rate of Interest on the
                      amount due and payable from (and including) the due date for payment until
                      payment of the sum outstanding (together with all accrued interest) in full
                      and such interest shall accrue from day to day and be computed on the basis of
                      a 365 day year and compounded monthly provided always that the Vendor may
                      elect to terminate this Agreement at any time after the Additional Time has
                      lapsed and the Purchaser is not ready and able to pay any outstanding monies
                      that are due including the Balance (as the case may be) in which case the
                      provisions of Section 6 shall thereafter apply; or
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">5.1.2</span>
                    <span>
                      the Vendor may (in its discretion) (but without prejudice to any other
                      available right or remedy in favour of the Vendor) if payment (together with
                      accrued interest thereon) in cleared funds shall not have been effected within
                      ten (10) days of the due date for payment or such longer period as the Vendor
                      may determine (during which time interest calculated in accordance with the
                      provisions of sub-clause 5.1.1 shall accrue) elect to treat such non-payment
                      as a fundamental breach by the Purchaser of the Purchaser’s obligations under
                      this Agreement and the provisions of Section 6 shall thereafter apply.
                    </span>
                  </span>
                </li>
              </ol>
            </li>

            <li>
              <span className="inner-list">
                <span className="agree-number">5.2</span>
                <span>
                  The Purchaser hereby agrees that the Agreed Rate of Interest payable on the
                  Purchaser’s breach of its payment obligations under this Agreement represents a
                  fair and reasonable pre-estimate of the loss to be suffered by the Vendor
                  resulting from the Purchaser’s breach of the Purchaser’s payment obligations
                  hereunder.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">6</span>
                <span className="bold">TERMINATION OF THIS AGREEMENT</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">6.1</span>
                <span className="">
                  If the Vendor makes the election referred to in sub-clause 5.1.1 or 5.1.2 the
                  Vendor shall without prejudice to its other rights and remedies be entitled to
                  rescind this Agreement in which event:
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={13}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list inner f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">6.1.1</span>
                <span>
                  the sum referred to in paragraph 4.1 of Schedule 1 shall be forfeited by the
                  Purchaser and retained by to the Vendor absolutely by way of agreed liquidated
                  damages. The Purchaser hereby agrees that the amount to be forfeited under this
                  clause 6.1.1 due to the Purchaser’s breach of its payment obligations under this
                  Agreement represents a fair and reasonable pre-estimate of the loss to be suffered
                  by the Vendor resulting from the Purchaser’s breach of the Purchaser’s payment
                  obligations under this Agreement. If the obligation to pay the sum referred to in
                  paragraph 4.1 of Schedule 1 pursuant to this clause 6.1.1 is found for any reason
                  to be void, invalid or otherwise inoperative so as to disentitle the Vendor from
                  claiming liquidated damages, the Vendor shall be entitled to claim against the
                  Purchaser for damages at law resulting from the Purchaser’s breach of its
                  obligations set out under this Agreement;
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">6.1.2</span>
                <span>
                  the Vendor shall thereupon be at liberty to proceed to negotiate with any other
                  person to require such other person to purchase the Sale Property on such terms
                  and conditions as may be agreed between the Vendor and such new purchaser and in
                  the event that such other person shall enter into an agreement in writing with the
                  Vendor (hereinafter called the New Agreement) and make all payments due under the
                  New Agreement the Vendor shall, subject to clause 6.1.3, pay to the Purchaser the
                  payments made on account of the Lease Premium as shall have been paid pursuant to
                  Section 4 (save for the amounts specified in paragraphs 4.1 and 9.1 of Schedule 1
                  which shall be forfeited and retained absolutely) after deduction therefrom of all
                  costs, charges and expenses incurred by the Vendor and the Vendor’s Advocates in
                  connection with the negotiation and granting of the rights under the{' '}
                  <span className="bold">New Agreement</span> and any other sums which may be
                  lawfully due from the Purchaser to the Vendor including interest which shall
                  notwithstanding termination nevertheless continue to be paid by the Purchaser on
                  the unpaid balance of the Lease Premium at the Agreed Rate of Interest from (and
                  including) the date of default in payment until all payments due under the New
                  Agreement (including all accrued interest) shall have been made in full;
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">6.1.3</span>
                <span>
                  the Vendor shall after exercise of its rights under sub-clause 6.1.1 be entitled
                  to retain all monies paid to the Vendor on account of the Lease Premium until such
                  time as the Vendor shall enter into the New Agreement and in the event that the
                  Vendor shall incur a loss by reason of diminution of the lease premium payable
                  under the New Agreement the Purchaser shall pay to the Vendor by way of agreed
                  liquidated damages an amount equal to the loss together with the expenses incurred
                  by the Vendor in respect of the New Agreement and together with all other sums
                  payable by the Purchaser pursuant to the provisions of sub-clause 6.1.2
                  (hereinafter collectively referred to as the{' '}
                  <span className="bold">Actual Loss</span>) and the Vendor shall be entitled to
                  set-off the Actual Loss from the balance of the monies retained by the Vendor on
                  account of the Lease Premium and any shortfall in satisfaction of the Actual Loss
                  shall forthwith become payable by the Purchaser to the Vendor without demand and
                  shall represent an agreed liquidated debt lawfully due and payable by the
                  Purchaser to the Vendor;
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">6.1.4</span>
                <span>
                  if the New Agreement realizes a profit this shall be retained by the Vendor; and
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">6.1.5</span>
                <span>
                  without prejudice to the Vendor’s rights under sub-clause 6.1.3, the Vendor shall
                  be entitled to recover by action any amounts found to be due to the Vendor in the
                  event of there being any deficiency.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={14}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number bold">7</span>
                <span className="bold">DISCLAIMER & DEFECTS LIABILITY</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 7.1</span>
                <span>The Purchaser agrees and confirms that:</span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number">7.1.1</span>
                    <span className="">
                      any condition or warranty whatsoever as to the condition of the Sale Property
                      or the fitness for any purpose is hereby excluded;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">7.1.2</span>
                    <span className="">
                      it has inspected or has been given the opportunity to inspect the Sale
                      Property, the Approved Documents, the Residential Development Plan, the Phase
                      1 Development Plan and any other plans referred to in this Agreement and that
                      it has entered into this Agreement solely on the basis of that inspection and
                      the terms hereof and not in reliance upon any representation whatsoever,
                      whether written oral or implied, made by or on behalf of the Vendor;
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">7.1.3</span>
                    <span className="">
                      purchases the Sale Property with full knowledge of its actual state and
                      condition and takes the Sale Property as it stands at the date hereof; and
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">7.1.4</span>
                    <span className="">
                      it enters into this Agreement solely as a result of searches, survey and
                      inspection it has carried out and on the basis of the terms of this Agreement
                      and not in reliance upon any representation either written or oral or implied
                      or made by or on behalf of the Vendor.
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">7.2</span>
                <span className="">
                  The Purchaser acknowledges that the Vendor’s obligations and liabilities in
                  relation to the granting of the Lease in accordance with the terms of this
                  Agreement are exhaustively defined in this Agreement and are in lieu of and to the
                  exclusion of any obligation or liability arising out of or in any way related to
                  any warranty, condition, term, duty, undertaking or representation of any kind,
                  express or implied, statutory, pursuant to common law or otherwise (including
                  without limitation, implied warranties or conditions of merchantability, of
                  fitness for a particular purpose, of reliability or availability or of accuracy)
                  that is not expressly provided for in this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">7.3</span>
                <span className="">
                  The Vendor will (as between the Vendor and the Purchaser) ensure that there are
                  remedied and put right within a reasonable time any material defects in the Sale
                  Property (other than those defects which shall arise from normal shrinkage and
                  drying out of plaster and/or timber or which shall comprise deterioration of
                  decoration occasioned by ordinary wear and tear) which shall appear within a
                  period of six (6) months from (and including) the date of the Architect’s
                  Certificate being 22 July 2022 (the{' '}
                  <span className="bold"> Defects Remedy Period</span>) and of which notification in
                  writing shall have been given by the Purchaser to the Vendor within the Defects
                  Remedy Period and which shall be defects of such nature as the Vendor is entitled
                  to require the Vendor’s contractor under the Vendor’s building contract for Phase
                  1 Development to remedy or put right provided that the Vendor shall only be bound
                  to remedy those material defects in respect of which the Vendor has received
                  notice in writing from the Purchaser prior to the expiry of the Defects Remedy
                  Period. It is hereby acknowledged and agreed between the Purchaser and the Vendor
                  that in relation to any defects in the Sale Property, the remedy contained in this
                  clause will be the Purchaser’s sole remedy. If the Purchaser delays in fulfilling
                  its obligations and taking possession of the Sale Property or if the Purchaser
                  shall not have notified the Vendor of the defects as aforesaid, it shall be deemed
                  to have acknowledged that the Sale Property was received in good order and
                  condition and the Purchaser shall have no claim against the Vendor for any defect
                  which may subsequently be found therein.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={15}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number bold">8</span>
                <span className="bold"> GENERAL PROVISIONS</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.1</span>
                <span>
                  The Sale Property will be sold with vacant possession by the Vendor and vacant
                  possession will be granted to the Purchaser on the Possession Date in accordance
                  with the terms of this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.2</span>
                <span>
                  The benefit of this Agreement is personal to the Purchaser and the Purchaser shall
                  not assign, charge, underlet, share, part with or otherwise in any way whatsoever
                  dispose of or deal with its interest under this Agreement or any part thereof or
                  any share therein without the prior written consent of the Vendor. The Vendor
                  shall require, inter alia, that the following conditions are satisfied prior to
                  the issuance of its consent:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number">8.2.1</span>
                    <span className="">
                      in the case of a sale of the Sale Property by the Purchaser, as beneficial
                      owner, to a New Purchaser (a <span className="bold">Resale</span>) the
                      Purchaser procuring the following to the satisfaction of the Vendor:
                    </span>
                  </span>
                  <ol className="agreement-list inner f-14">
                    <li>
                      <span className="inner-list">
                        <span className="agree-number">(a)</span>
                        <span className="">
                          a novation agreement (the terms of which shall be approved and be
                          acceptable to the Vendor and the Vendor’s Advocates) is entered into with
                          the New Purchaser (the <span className="bold">Novation Agreement</span> )
                          in respect of this Agreement pursuant to which the Purchaser shall
                          extinguish all its rights and obligations under this Agreement and
                          transfer such rights and obligations to the New Purchaser;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="inner-list">
                        <span className="agree-number">(b)</span>
                        <span className="">
                          an administrative fee is paid by the Purchaser to the Vendor in respect of
                          a Resale of an amount equivalent to two percent (2%) of the consideration
                          payable by the New Purchaser on the Resale;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="inner-list">
                        <span className="agree-number">(c)</span>
                        <span className="">
                          all costs, charges and expenses incurred by the Vendor including without
                          limitation the Vendor’s Advocates legal fees in connection with the Resale
                          shall be payable by the Purchaser upon execution of the Novation
                          Agreement;
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="inner-list">
                        <span className="agree-number">(d)</span>
                        <span className="">
                          the Purchaser has paid twenty percent (20%) of the Lease Premium to the
                          Vendor; and
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="inner-list">
                        <span className="agree-number">(e)</span>
                        <span className="">
                          the New Lease Premium being at a market value provided that it is not less
                          than the Lease Premium.
                        </span>
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">8.3</span>
                <span className="">
                  No damage to or destruction of the Phase 1 Development, the Residential
                  Development or the Sale Property or any part of the Phase 1 Development, the
                  Residential Development or the Sale Property howsoever occasioned shall in any way
                  affect the obligations of the parties under this Agreement, unless such damage or
                  destruction is caused by the Vendor’s negligence and the insurance in respect of
                  the Phase 1 Development, the Residential Development or the Sale Property (as the
                  case may be) is vitiated or voided as a result of such negligence{' '}
                  <span className="underline">provided always that</span> all monies received from
                  the insurers for such damage or destruction shall be fully applied towards the
                  restoration of the Phase 1 Development, the Residential Development or the Sale
                  Property (as the case may be) or any part thereof.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">8.4</span>
                <span className="">
                  On signing the Agreement, the Purchaser shall assume the right of the Vendor to
                  grant the Lease and shall not require any evidence of or raise any unreasonable
                  objection, requisition or inquiry in respect of the Vendor’s title to the
                  Property.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">8.5</span>
                <span className="">The Purchaser agrees and confirms that:</span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number">8.5.1 </span>
                    <span className="">
                      any condition or warranty whatsoever as to the condition of the Sale Property
                      or the fitness for any purpose is hereby excluded;
                    </span>
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={16}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list inner f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number">8.5.2 </span>
                <span className="">
                  it enters into this Agreement solely as a result of searches, survey and
                  inspections it has carried out and on the basis of the terms of this Agreement and
                  not in reliance upon any representation either written or oral or implied or made
                  by or on behalf of the Vendor; and
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">8.5.3 </span>
                <span className="">
                  this Agreement constitutes the whole and only agreement between the parties
                  relating to the sale and purchase of the Sale Property and supercedes and
                  extinguishes any prior agreements, undertakings, representations, warranties and
                  arrangements of any nature whatsoever whether or not in writing relating to the
                  Sale Property or its sale and purchase.
                </span>
              </span>
            </li>
          </ol>
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.6</span>
                <span>
                  Subject to clause 3.1.1, no amendment or variation to this Agreement shall be
                  effectual or binding on the Parties hereto unless it is in writing and duly
                  executed by the Parties hereto.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.7</span>
                <span>
                  The parties acknowledge and agree that following the execution of this Agreement,
                  the Vendor shall, in consultation with the Purchaser and/or the Purchaser’s
                  Advocates, be entitled to amend the terms of this Agreement (including the
                  Management Structure) and the Lease to make them compliant with any changes
                  arising from the amendments to or the enactment of any Applicable Laws.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.8</span>
                <span>
                  The Purchaser acknowledges that the Purchaser’s obligations under this Agreement
                  and/or the Lease shall not be affected or lessened in any way by the fact that
                  there may now or subsequently exist any variations to the transaction documents as
                  a result of: (i) a change or enforcement of the Applicable Laws; or (ii)
                  directives of any Competent Authority; or (iii) changes to the Management
                  Structure.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.9</span>
                <span>
                  The parties shall from time to time and at all times after execution of this
                  Agreement, do or procure the doing of all acts and/or execute or procure the
                  execution of all documents which may be necessary or required for giving full
                  effect to this Agreement and securing to the parties the full benefit of the
                  rights, powers and remedies conferred upon the parties in this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.10</span>
                <span>
                  No immaterial error or omission or misstatement in this Agreement or the Approved
                  Documents or the Sale Property Plan or the Residential Development Plan shall in
                  any way affect the obligations of the parties under this Agreement or entitle any
                  party to damages or compensation.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.11</span>
                <span>
                  Notwithstanding the execution of the Lease, this Agreement shall remain in force
                  with regard to any rights, obligations or restrictions hereunder not provided for
                  in the Lease.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.12</span>
                <span>
                  No delay in stamping and registering the Lease (and as a result thereof) in
                  granting possession of the Sale Property to the Purchaser shall in any way affect
                  the obligations of the Purchaser under the Agreement and the Vendor shall not in
                  any event be liable to the Purchaser as a result of such delay.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.13</span>
                <span>
                  If any tax (including value added tax or sales tax) shall be chargeable in respect
                  of any moneys payable under this Agreement the Purchaser shall pay in addition to
                  the amounts otherwise payable the amount of the tax so chargeable.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.14</span>
                <span>
                  All payments by the Purchaser hereunder or pursuant hereto shall be made in
                  cleared funds, without and free from any set-off, counter-claim or deduction
                  whatsoever.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.15</span>
                <span>
                  The Vendor’s obligations and duties in respect of the carrying out and completion
                  of the Phase 1 Development and the Sale Property (and any additional works
                  requested by the Purchaser hereunder) shall be limited to the contractual
                  obligations and duties contained in this Agreement and any claim by the Purchaser
                  against the Vendor in tort is excluded.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={17}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.16</span>
                <span>
                  The Vendor shall be under no obligation or liability whatsoever for any special,
                  collateral, punitive, incidental, consequential or economic loss or damages
                  (including any costs or expenses in respect thereof) (exemplary or otherwise) or
                  for any direct or indirect loss of any kind whatsoever, howsoever and by
                  whomsoever caused that is suffered by the Purchaser or any third party in
                  connection with this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.17</span>
                <span>
                  No failure or delay to exercise any power, right or remedy by the Vendor shall
                  operate as a waiver of that right, power or remedy and no single or partial
                  exercise by the Vendor of any right, power or remedy shall preclude its further
                  exercise or the exercise of any other right, power or remedy.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.18</span>
                <span>
                  This Agreement constitutes the entire agreement between the parties and any
                  representations, warranties or statements, whether written, oral or implied, and
                  whether made before or after this Agreement are expressly excluded.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.19</span>
                <span>
                  If any term or condition of this Agreement shall to any extent be found or held to
                  be invalid or unenforceable, the parties shall negotiate in good faith to amend
                  such term or condition so as to be valid and enforceable and to be construed with
                  the interests of the parties as contained herein.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.20</span>
                <span>
                  Each of the provisions of this Agreement is severable and distinct from the others
                  and, if at any time one or more of these provisions is or becomes invalid, illegal
                  or unenforceable, the validity, legality and enforceability of the remaining
                  provisions shall not in any way be affected or impaired.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.21</span>
                <span>
                  The construction, validity and performance of this Agreement shall be governed by
                  and construed in accordance with the laws of Kenya.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.22</span>
                <span>
                  The Purchaser confirms and agrees that the user of the Sale Property shall be in
                  accordance with the user set out in paragraph 8 of Schedule 1.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.23</span>
                <span>
                  Each of the parties hereby agrees and confirms for the purposes of the Law of
                  Contract Act (Chapter 23, laws of Kenya) and the Applicable Laws that it has
                  executed this Agreement with the intention to bind itself or to the contents
                  hereof.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.24</span>
                <span>
                  The Purchaser shall fully and effectively (on a full and unqualified indemnity
                  basis) indemnify and hold harmless the Vendor from and against any loss, cost,
                  claim, demand, expense, proceeding and/or damage suffered or incurred by the
                  Vendor arising in any way out of or in connection with any breach of or failure by
                  the Purchaser to observe and perform the Purchaser’s duties and obligations under
                  or pursuant to this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 8.25</span>
                <span>
                  The Purchaser shall at its own cost, from time to time on request, do or procure
                  the doing of all acts and/or execute or procure the execution of all documents in
                  a form satisfactory to the Vendor for giving full effect to this Agreement and
                  securing to the Vendor the full benefit of the rights, powers and remedies
                  conferred upon the Vendor in this Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 9</span>
                <span className="bold">DISPUTES</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 9.1</span>
                <span className="">
                  Any dispute, controversy or claim arising out of or relating to this Agreement or
                  a termination hereof (including without prejudice to the generality of the
                  foregoing, whether as to its interpretation, application or implementation), shall
                  be resolved by way of consultation held in good faith between the parties. Such
                  consultation shall begin immediately after one party has delivered to the other
                  written request for such consultation (the{' '}
                  <span className="bold"> Notice for Consultation </span>). The other party shall
                  submit a response in similar detail to the party requesting for consultation. The
                  representatives of the parties, who shall have authority to settle the dispute
                  shall meet at a mutually acceptable time and place in an attempt to resolve the
                  dispute.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={18}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number"> 9.2</span>
                <span>
                  Following the issuance of the Notice for Consultation, if the dispute has not been
                  resolved within fifteen (15) days (or such other extended time as the parties may
                  mutually agree to, the dispute, objection or claim, shall, to the extent permitted
                  by law, upon request of any party by written notice to the other party (the{' '}
                  <span className="bold">Arbitration Notice</span>) be submitted to arbitration in
                  accordance with clause 9.3 below.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">9.3</span>
                <span>
                  Should any dispute, controversy or claim as is referred to in clause 9.1 arise
                  between the parties and the consultation process referred to in clause 9.1 shall
                  have not resolved such dispute, the dispute shall be settled by binding
                  arbitration in accordance with the procedural rules of the Chartered Institute of
                  Arbitrators Kenya (the <span className="bold">CIArb Arbitration Rules </span>) (as
                  shall be applicable at the point of such dispute, controversy or claim. Each party
                  hereby consents to such arbitration and such CIArb Arbitration Rules are deemed to
                  be incorporated by reference in this clause 9.3.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 9.4</span>
                <span>
                  The dispute shall be referred to a single arbitrator (the Arbitrator) to be
                  mutually agreed by the parties within a period of seven (7) days from the date of
                  the Arbitration Notice (the <span className="bold">Appointment Period </span>).
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 9.5</span>
                <span>
                  If parties fail to mutually agree on the appointment of the Arbitrator within the
                  Appointment Period, the Arbitrator shall be appointed in the manner prescribed
                  under the CIArb Arbitration Rules.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 9.6</span>
                <span>
                  The award of the Arbitrator shall be final and binding on the parties to the
                  extent permitted by law and any party may apply to a Kenyan court of competent
                  jurisdiction for enforcement of such award. The award of the arbitration may take
                  the form of an order to pay an amount or to perform or to refrain from or prevent
                  certain activities.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 9.7</span>
                <span>
                  The place and seat of arbitration shall be Nairobi and the language of arbitration
                  shall be English.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 9.8</span>
                <span>
                  Notwithstanding the foregoing, a party is entitled to seek preliminary injunctive
                  relief or interim or conservatory measures from a court in Kenya of competent
                  jurisdiction pending the final decision or award of the arbitrator.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 10</span>
                <span className="bold">NOTICES</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 10.1</span>
                <span>
                  Any notice, demand or other document to be given or made by one party to any other
                  shall be in writing and shall be delivered by hand or courier service or mailed by
                  certified or registered mail or sent by email as follows:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 10.1.1</span>
                    <span>
                      <span className="underline d-block">
                        in the case of a notice to the Vendor, to:{' '}
                      </span>
                      <span className="address">
                        {' '}
                        Address: Mi Vida Thika Road Limited <br></br>
                        P.O. Box 43233-00100 <br></br>
                      </span>
                      <span className="underline">Nairobi </span>
                      <span className="d-block"> Attn: Krupa Chohan</span>
                      <span>
                        Email: <a href="#">kc@mividahomes.com </a>
                      </span>
                      <span>
                        or such other address or e-mail address as the Vendor shall have specified
                        by giving prior written notice to the Purchaser;
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 10.1.2</span>
                    <span>
                      <span className="underline d-block">in the case of the Purchaser, to: </span>
                      <span className="d-block">
                        {' '}
                        the address specified in paragraph 5.1 of Schedule 1 or such other address
                        or e-mail address as the Purchaser shall have specified by giving prior
                        written notice to the Vendor.
                      </span>
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number"> 10.2</span>
                <span>
                  Save as otherwise expressly provided herein, any notice, demand or document to be
                  given or made by one party to this Agreement to the other shall be deemed made:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 10.2.1</span>
                    <span>
                      <span className="d-block">
                        if made by e-mail when the e-mail is received by the party during normal
                        business hours on a Business Day){' '}
                        <span className="underline">provided that</span> the appropriate
                        acknowledgement of proper transmission is obtained by the sender;
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 10.2.2</span>
                    <span>
                      <span className="d-block">
                        when left during normal working hours on a Business Day at the principal
                        office of each party; or
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number"> 10.2.3</span>
                    <span>
                      <span className="d-block">
                        {' '}
                        seven (7) Business Days after posting by registered post{' '}
                        <span className="underline">provided that </span> proof is given that the
                        notice, demand or document was properly addressed adequately stamped and put
                        into the post by registered post.
                      </span>
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 11</span>
                <span className="bold d-block">EXECUTORY AGREEMENT</span>
              </span>
              <p className="pl-left mb-0">
                This Agreement is an executory agreement only and shall not operate or be deemed to
                operate as a lease of the Sale Property.
              </p>
            </li>
            <li>
              <span className="inner-list">
                <span className="">
                  <span className="bold">IN WITNESS WHEREOF </span> this Agreement has been duly
                  executed by the parties as of the day and year first before written.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={19}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="text-center f-16 bold"> SCHEDULE 1</p>
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 1</span>
                <span className="bold">Sale Property</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">1.1</span>
                <span>
                  <input
                    className="form-control contract-edit"
                    readOnly={inputReadOnly}
                    placeholder=" Apartment Number 8 on the 8th Floor in Tower B in the Phase 1 Development being a
                Two (2) bedroom unit with a Domestic Servant Quarter (DSQ) as further identified
                on the Sale Property Plan and in the Lease."
                    value={editedText.sale_property_text}
                    onChange={(e) =>
                      setEditedText({ ...editedText, sale_property_text: e.target.value })
                    }
                    size={80}
                  />
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 2</span>
                <span className="bold">Purchaser</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">2.1</span>
                <span>
                  <span className="bold d-flex">
                    {' '}
                    <input
                      className="form-control new-contract"
                      placeholder="  SHEILA KONYU MUCHEMI"
                      value={editedText.purchaser_text}
                      onChange={(e) =>
                        setEditedText({ ...editedText, purchaser_text: e.target.value })
                      }
                      size={50}
                    />
                    <input
                      className="form-control contract-edit"
                      readOnly={inputReadOnly}
                      placeholder="(Kenyan ID Number 22245855)"
                      value={editedText.purchaser_text_normal}
                      onChange={(e) =>
                        setEditedText({ ...editedText, purchaser_text_normal: e.target.value })
                      }
                      size={50}
                    />
                  </span>
                  for the purposes hereof of Post Office Box Number 55176-00200, Nairobi. The
                  Purchaser shall, where the context so admits, include the Purchaser’s personal
                  representatives, heirs and permitted assigns.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 3</span>
                <span className="bold">LEASE PREMIUM</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.1</span>
                <span>
                  The Lease Premium payable by the Purchaser shall be the sum
                  <input
                    className="form-control contract-edit"
                    readOnly={inputReadOnly}
                    placeholder=" of Kenya Shillings
                Fourteen Million One Hundred and Eighty Thousand (KES. 14,180,000.00)"
                    value={editedText.lease_text_1}
                    onChange={(e) => setEditedText({ ...editedText, lease_text_1: e.target.value })}
                    size={60}
                  />{' '}
                  and shall be payable as set out below:
                </span>
              </span>
              <ol className="agreement-list inner f-14">
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.1.1</span>
                    <span>
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="the sum of Kenya Shillings Two Million Eight Hundred and Thirty-Six Thousand
                (KES. 2,836,000.00)"
                        value={editedText.lease_text_2}
                        onChange={(e) =>
                          setEditedText({ ...editedText, lease_text_2: e.target.value })
                        }
                        size={60}
                      />
                      is payable to the Vendor on or before the execution of this Agreement (receipt
                      whereof the Vendor hereby acknowledges); and
                    </span>
                  </span>
                </li>
                <li>
                  <span className="inner-list">
                    <span className="agree-number">3.1.2</span>
                    <span>
                      the balance of the Lease Premium being the sum of
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="Kenya Shillings Eleven
                Million Three Hundred and Forty-Four Thousand (KES. 11,344,000.00)"
                        value={editedText.lease_text_3}
                        onChange={(e) =>
                          setEditedText({ ...editedText, lease_text_3: e.target.value })
                        }
                        size={60}
                      />{' '}
                      (the
                      <span className="bold"> Balance </span> ) shall be payable to the Vendor on or
                      before the Completion Date.
                    </span>
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.2</span>
                <span>
                  If the Purchaser is in default of any of its obligations under this Agreement,
                  without prejudice to the Vendor’s other rights and remedies hereunder, the
                  Purchaser agrees and acknowledges that any money paid to the Vendor or for the
                  account of the Vendor, Vendor’s Advocates including but not limited to the
                  instalments on the Lease Premium shall be allocated towards the payment of any
                  interest accrued under this Agreement, any legal fees, Service Charge,
                  Infrastructure Levy, any deposits (including the restoration of any deposits), the
                  reduction of the Balance and any other payments due from the Purchaser under this
                  Agreement in whichever order of priority as the Vendor shall determine in its sole
                  and absolute discretion.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">3.3</span>
                <span>
                  The Purchaser shall at all times be entitled to make a payment before the date on
                  which it is due in terms of this Agreement or to make larger payments than the
                  payments for which this Agreement provides. The Purchaser shall not have the right
                  to defer or withhold payment of any amounts due and payable in terms of this
                  Agreement.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">4</span>
                <span className="bold">TERMINATION OF AGREEMENT</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">4.1</span>
                <span className="">
                  The amount to be forfeited under clause 6.1.1: all sums paid by the Purchaser to
                  the Vendor pursuant to paragraph 3 of this Schedule 1 up to the date of forfeiture
                  subject to a maximum amount equivalent to ten percent (10%) of the Lease Premium.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold">5</span>
                <span className="bold">NOTICES</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">5.1</span>
                <span className="">The Purchaser’s address:</span>
              </span>
              <span className="bold d-block pl-left">
                <input
                  className="form-control contract-edit"
                  readOnly={inputReadOnly}
                  placeholder="SHEILA KONYU MUCHEMI"
                  value={editedText.address_1}
                  onChange={(e) => setEditedText({ ...editedText, address_1: e.target.value })}
                  size={30}
                />
              </span>
              <span className="d-block pl-left">
                <input
                  className="form-control contract-edit"
                  readOnly={inputReadOnly}
                  placeholder="Post Office Box 55176-00200, Nairobi"
                  value={editedText.address_2}
                  onChange={(e) => setEditedText({ ...editedText, address_2: e.target.value })}
                  size={30}
                />
              </span>
              <span className="d-block pl-left">
                <input
                  className="form-control contract-edit"
                  readOnly={inputReadOnly}
                  placeholder=" E-mail address: sheila.muchemi@gmail.com; mwmuchemi@gmail.com"
                  value={editedText.address_3}
                  onChange={(e) => setEditedText({ ...editedText, address_3: e.target.value })}
                  size={30}
                />
              </span>
              <span className="d-block pl-left">
                <input
                  className="form-control contract-edit"
                  readOnly={inputReadOnly}
                  placeholder="Phone Number: 0702-734-272z"
                  value={editedText.address_4}
                  onChange={(e) => setEditedText({ ...editedText, address_4: e.target.value })}
                  size={30}
                />
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={20}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 6</span>
                <span className="bold">CAR PARK SPACES</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">6.1</span>
                <span>
                  <input
                    className="form-control contract-edit"
                    readOnly={inputReadOnly}
                    placeholder="Number of car park space: One (1)."
                    value={editedText.car_spaces}
                    onChange={(e) => setEditedText({ ...editedText, car_spaces: e.target.value })}
                    size={40}
                  />
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 7</span>
                <span className="bold"> PHASE 1 MANAGEMENT COMPANY</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">7.1</span>
                <span>Number of ordinary shares to be allotted: one (1) ordinary share.</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 8</span>
                <span className="bold">USER</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">8.1</span>
                <span>
                  The user of the Sale Property shall be for residential purposes only provided that
                  at no time shall more than such number of individuals as may be specified by the
                  Phase 1 Management Company be entitled to occupy the Sale Property. The Sale
                  Property may also be used for short term and long-term rentals from time to time.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number  bold">9</span>
                <span className=" bold">
                  LEGAL FEES, STAMP DUTY, REGISTRATION COSTS AND DISBURSEMENTS
                </span>
              </span>
            </li>
          </ol>
          <div className="contract-table">
            <table className="w-100">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    {' '}
                    <p className="mb-0">ITEM</p>
                  </th>
                  <th>
                    {' '}
                    <p className="mb-0">AMOUNT</p>
                  </th>
                  <th>
                    {' '}
                    <p className="mb-0">DATE OF PAYMENT</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>9.1</td>
                  <td colSpan={3}>
                    <div className="contract-content">
                      <p className="mb-1">
                        Contribution towards Vendor’s Advocates legal fees (including the office
                        disbursements and VAT) payable by the Purchaser shall be the sum of
                        KES.124,700.00 made up as follows:
                      </p>
                      <div className="legal-fee">
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <td>
                                <p className="mb-0">
                                  <input
                                    className="form-control contract-edit"
                                    readOnly={inputReadOnly}
                                    placeholder="Legal fees"
                                    value={editedText.legal_fee}
                                    onChange={(e) =>
                                      setEditedText({ ...editedText, legal_fee: e.target.value })
                                    }
                                    size={20}
                                  />
                                </p>
                              </td>
                              <td>
                                <p className="mb-0 text-right">
                                  <input
                                    className="form-control contract-edit"
                                    readOnly={inputReadOnly}
                                    placeholder="KES. 100,000.00"
                                    value={editedText.legal_fee_kes}
                                    onChange={(e) =>
                                      setEditedText({
                                        ...editedText,
                                        legal_fee_kes: e.target.value,
                                      })
                                    }
                                    size={20}
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="mb-0">
                                  <input
                                    className="form-control contract-edit"
                                    readOnly={inputReadOnly}
                                    placeholder="Office Disbursements"
                                    value={editedText.office_dis}
                                    onChange={(e) =>
                                      setEditedText({ ...editedText, office_dis: e.target.value })
                                    }
                                    size={20}
                                  />
                                </p>
                              </td>
                              <td>
                                <p className="mb-0 text-right">
                                  <input
                                    className="form-control contract-edit"
                                    readOnly={inputReadOnly}
                                    placeholder="KES. 7,500.00"
                                    value={editedText.office_dis_kes}
                                    onChange={(e) =>
                                      setEditedText({
                                        ...editedText,
                                        office_dis_kes: e.target.value,
                                      })
                                    }
                                    size={20}
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="mb-0">
                                  <input
                                    className="form-control contract-edit"
                                    readOnly={inputReadOnly}
                                    placeholder="VAT (on the legal fees and office disbursements on the basis of
                  16% VAT)"
                                    value={editedText.vat}
                                    onChange={(e) =>
                                      setEditedText({ ...editedText, vat: e.target.value })
                                    }
                                    size={20}
                                  />
                                </p>
                              </td>
                              <td>
                                <p className="mb-0 text-right">
                                  <input
                                    className="form-control contract-edit"
                                    readOnly={inputReadOnly}
                                    placeholder="KES. 17,200.00"
                                    value={editedText.vat_kes}
                                    onChange={(e) =>
                                      setEditedText({ ...editedText, vat_kes: e.target.value })
                                    }
                                    size={20}
                                  />
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="mb-2">
                        The
                        <input
                          className="form-control contract-edit"
                          readOnly={inputReadOnly}
                          placeholder="deposit of KES. 87,290.00 being seventy percent (70%)"
                          value={editedText.deposit}
                          onChange={(e) =>
                            setEditedText({ ...editedText, deposit: e.target.value })
                          }
                          size={40}
                        />{' '}
                        of the legal fees (including disbursements and VAT) shall be paid to the
                        Vendor’s Advocates on or before the execution of the Agreement for Lease.
                      </p>
                      <p className="mb-0">
                        The{' '}
                        <input
                          className="form-control contract-edit"
                          readOnly={inputReadOnly}
                          placeholder="balance of KES. 37,410.00 being thirty percent (30%)"
                          value={editedText.balance}
                          onChange={(e) =>
                            setEditedText({ ...editedText, balance: e.target.value })
                          }
                          size={40}
                        />{' '}
                        of the legal fees (including disbursements and VAT) shall be paid to the
                        Vendor’s Advocates on or before the Completion Date.
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>9.2</td>
                  <td>
                    <p className="mb-0">
                      Estimated Stamp duty on the Lease (being 4% of the Lease Premium (subject to
                      valuation by a Government Valuer{' '}
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES 567,240.00"
                        value={editedText.amount_2}
                        onChange={(e) => setEditedText({ ...editedText, amount_2: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.3</td>
                  <td>
                    <p className="mb-0">
                      Estimated Stamp duty on the Charge being 0.1% of the value of the financed
                      amount (where the Purchaser is financed).
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="To be confirmed once financed amount is determined"
                        value={editedText.amount_3}
                        onChange={(e) => setEditedText({ ...editedText, amount_3: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.4</td>
                  <td>
                    <p className="mb-0">Estimated stamping and registration costs</p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 27,000.00"
                        value={editedText.amount_4}
                        onChange={(e) => setEditedText({ ...editedText, amount_4: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      50% on signing the Agreement and the balance of 50% on or before the
                      Completion Date
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Page>
      <Page pageNumber={21}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <div className="contract-table">
            <table className="w-100">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    {' '}
                    <p className="mb-0">ITEM</p>
                  </th>
                  <th>
                    {' '}
                    <p className="mb-0">AMOUNT</p>
                  </th>
                  <th>
                    {' '}
                    <p className="mb-0">DATE OF PAYMENT</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>9.5</td>
                  <td>
                    <p className="mb-0">
                      Estimated Vendor’s Advocates costs for the incorporation of the Phase 1
                      Management Company
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 5,000.00"
                        value={editedText.amount_5}
                        onChange={(e) => setEditedText({ ...editedText, amount_5: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.6</td>
                  <td>
                    <p className="mb-0">
                      Estimated Vendor’s Advocates costs for the incorporation of the Residential
                      Management Company (if applicable)
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 5,000.00"
                        value={editedText.amount_6}
                        onChange={(e) => setEditedText({ ...editedText, amount_6: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      Shall be payable by the Purchaser within fourteen (14) days of written notice
                      from the Vendor
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>9.7</td>
                  <td>
                    <p className="mb-0">
                      Cost of transfer/allotment of one (1) share in the Phase 1 Management Company
                      to the Company Secretary
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 5,000.00"
                        value={editedText.amount_7}
                        onChange={(e) => setEditedText({ ...editedText, amount_7: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.8</td>
                  <td>
                    <p className="mb-0">
                      Estimated Cost of transfer/allotment of one (1) share in the Residential
                      Management Company to the Phase 1 Management Company payable to the Company
                      Secretary (if applicable)
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 5,000.00"
                        value={editedText.amount_8}
                        onChange={(e) => setEditedText({ ...editedText, amount_8: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">
                      Shall be payable by the Purchaser within fourteen (14) days of written notice
                      from the Vendor
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>9.9</td>
                  <td>
                    <p className="mb-0">
                      Purchaser’s share of the legal fees for undertaking the transfer of the
                      reversionary interest
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 5,000.00"
                        value={editedText.amount_9}
                        onChange={(e) => setEditedText({ ...editedText, amount_9: e.target.value })}
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    {' '}
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.10</td>
                  <td>
                    <p className="mb-0">
                      Purchaser’s share of the consideration for the reversionary interest in the
                      Phase 1 Land
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 5,000.00"
                        value={editedText.amount_10}
                        onChange={(e) =>
                          setEditedText({ ...editedText, amount_10: e.target.value })
                        }
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.11</td>
                  <td>
                    <p className="mb-0">
                      Estimated electricity meter deposit and connection charges (subject to actual
                      cost incurred from the service provider)
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 1,000.00"
                        value={editedText.amount_11}
                        onChange={(e) =>
                          setEditedText({ ...editedText, amount_11: e.target.value })
                        }
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.12</td>
                  <td>
                    <p className="mb-0">
                      Estimated disbursements for water infrastructure per unit (subject to actual
                      cost incurred from the service provider)
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 5,000.00"
                        value={editedText.amount_12}
                        onChange={(e) =>
                          setEditedText({ ...editedText, amount_12: e.target.value })
                        }
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">On or before the Completion Date</p>
                  </td>
                </tr>
                <tr>
                  <td>9.13</td>
                  <td>
                    <p className="mb-0">
                      (If georeferenced plans for the Sale Property are required by the Lands
                      Registry), estimated disbursements for preparation and approval of
                      georeferenced plans. This is an estimate and the final costs (which may be
                      lower or higher) will be confirmed.
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      <input
                        className="form-control contract-edit"
                        readOnly={inputReadOnly}
                        placeholder="KES. 22,000.00"
                        value={editedText.amount_13}
                        onChange={(e) =>
                          setEditedText({ ...editedText, amount_13: e.target.value })
                        }
                        size={20}
                      />
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      Within fourteen (14) days of receipt of a notice from the Vendor.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 10</span>
                <span className="bold">INITIAL PROVISIONAL SERVICE CHARGE</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">10.1</span>
                <span>
                  The Initial Provisional Service Charge shall be{' '}
                  <input
                    className="form-control contract-edit"
                    readOnly={inputReadOnly}
                    placeholder="Kenya Shillings forty-eight
                thousand (KES.48,000.00)"
                    value={editedText.initial_1}
                    onChange={(e) => setEditedText({ ...editedText, initial_1: e.target.value })}
                    size={40}
                  />{' '}
                  and being an amount equivalent to six (6) months estimated Service Charge.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 11</span>
                <span className="bold"> INITIAL SERVICE CHARGE DEPOSIT</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">11.1</span>
                <span>
                  The Initial Service Charge Deposit shall be{' '}
                  <input
                    className="form-control contract-edit"
                    readOnly={inputReadOnly}
                    placeholder=" Kenya Shillings forty-eight thousand
                (KES.48,000.00)"
                    value={editedText.initial_2}
                    onChange={(e) => setEditedText({ ...editedText, initial_2: e.target.value })}
                    size={40}
                  />{' '}
                  and being an amount equivalent to six (6) months estimated Service Charge.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={22}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <ol className="agreement-list f-14">
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 12</span>
                <span className="bold">INITIAL PROVISIONAL INFRASTRUCTURE LEVY</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">12.1</span>
                <span>
                  The Initial Provisional Infrastructure Levy shall be{' '}
                  <input
                    className="form-control contract-edit"
                    readOnly={inputReadOnly}
                    placeholder="Kenya Shillings six thousand
                (KES. 6,000.00)"
                    value={editedText.initial_3}
                    onChange={(e) => setEditedText({ ...editedText, initial_3: e.target.value })}
                    size={40}
                  />{' '}
                  and being an amount equivalent to six (6) months estimated Infrastructure Levy.
                </span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number bold"> 13</span>
                <span className="bold"> INITIAL INFRASTRUCTURE LEVY DEPOSIT</span>
              </span>
            </li>
            <li>
              <span className="inner-list">
                <span className="agree-number">13.1</span>
                <span>
                  13.1 The Initial Infrastructure Levy Deposit shall be{' '}
                  <input
                    className="form-control contract-edit"
                    readOnly={inputReadOnly}
                    placeholder="Kenya Shillings six thousand
                (KES. 6,000.00)"
                    value={editedText.initial_4}
                    onChange={(e) => setEditedText({ ...editedText, initial_4: e.target.value })}
                    size={40}
                  />{' '}
                  and being an amount equivalent to six (6) months estimated Infrastructure Levy.
                </span>
              </span>
            </li>
          </ol>
        </div>
      </Page>
      <Page pageNumber={23}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="text-center f-16 bold">SCHEDULE 2 FORM OF LEASE</p>
        </div>
      </Page>
      <Page pageNumber={24}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="text-center f-16 bold">SCHEDULE 3 SALE PROPERTY PLAN</p>
        </div>
      </Page>
      <Page pageNumber={25}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="text-center f-16 bold">SCHEDULE 4 RESIDENTIAL DEVELOPMENT PLAN</p>
        </div>
      </Page>
      <Page pageNumber={26}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="text-center f-16 bold">SCHEDULE 5 PLANS</p>
        </div>
      </Page>
      <Page pageNumber={27}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="text-center f-16 bold">SCHEDULE 6 LSK CONDITIONS</p>
        </div>
      </Page>
      <Page pageNumber={28}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <p className="text-center f-16 bold">EXECUTION</p>
          <div className="execution-table">
            <table className="w-100 f-14">
              <tbody>
                <tr>
                  <td>
                    <div className="execution-tab">
                      <p className="bold mb-0">THE VENDOR:</p>
                      <p className="mb-0">SIGNED for and on behalf of</p>
                    </div>
                  </td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="execution-tab">
                      <p className="bold mb-0">MI VIDA THIKA ROAD LIMITED</p>
                    </div>
                  </td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="execution-tab">
                      <p className="mb-0">in the presence of:</p>
                    </div>
                  </td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className="dashed-blank relative"> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> AUTHORISED SIGNATORY/DIRECTOR</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className="dashed-blank relative"> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> AUTHORISED SIGNATORY/DIRECTOR</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>ADVOCATE</span>
                  </td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="sign-content">
            <span className="f-14">
              I CERTIFY THAT the above named ……………………………………… and …………………………………… being the person(s)
              authorised to sign this Agreement on behalf of{' '}
              <span className="bold">MI VIDA THIKA ROAD LIMITED </span> appeared before me on the
              .……….. day of ………………………… 20 …… and being known to me/being identified by ID/PP Numbers
              ……………………………….. and …………………………………………. respectively acknowledged the above signatures or
              mark to be his/hers/theirs and that he/she/they had freely and voluntarily executed
              this Agreement and understood its contents.
            </span>
          </div>
          <div className="text-center certify mt-5 mb-3">
            <span className="f-14 d-block">-----------------------------------------</span>
            <span className="f-14 d-block">Signature and Designation of</span>
            <span className="f-14 d-block">Person Certifying</span>
          </div>
        </div>
      </Page>
      <Page pageNumber={29}>
        <div className="page border border-2 p-4 bg-white mb-4">
          <div className="execution-table purchaser">
            <p className="f-16 bold">THE PURCHASER:</p>
            <table className="w-100 f-14">
              <tbody>
                <tr>
                  <td>
                    <div className="execution-tab">
                      <p className=" mb-0">
                        SIGNED by <span className="bold">SHEILA KONYU MUCHEMI </span>{' '}
                      </p>
                    </div>
                  </td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="execution-tab">
                      <p className="mb-0">in the presence of:</p>
                    </div>
                  </td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>ADVOCATE</span>
                  </td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span> </span>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    <span> ] </span>
                  </td>
                  <td>
                    <span className=""> </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="sign-content mt-5">
              <span className="f-14">
                I CERTIFY THAT the above-named <span className="bold"> SHEILA KONYU MUCHEMI </span>{' '}
                appeared before me on the ……….. day of ………………………… 20 …… and being known to me/being
                identified by Kenyan ID 22245855 acknowledged the above signature or mark to be hers
                and that she had freely and voluntarily executed this Agreement and understood its
                contents.
              </span>
            </div>
            <div className="text-center certify mt-5 mb-3">
              <span className="f-14 d-block">-----------------------------------------</span>
              <span className="f-14 d-block">Signature and Designation of</span>
              <span className="f-14 d-block">Person Certifying</span>
            </div>
          </div>
          <div className="drawn-cnt mt-4 f-14 mb-3">
            <span className="d-block underline">Drawn by:</span>
            <span className="d-block">Anjarwalla & Khanna LLP</span>
            <span className="d-block">ALN House, Eldama Ravine Close</span>
            <span className="d-block">Off Eldama Ravine Road </span>
            <span className="d-block">Westlands </span>
            <span className="d-block">P.O. Box 200 – 00606 </span>
            <span className="d-block underline">Nairobi</span>
          </div>
        </div>
      </Page> */}
    </ContentLayout >
  );
};

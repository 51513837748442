import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Controller, useForm, UseFormRegisterReturn } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  registration: Partial<UseFormRegisterReturn>;
  value?: string;
  floating?: boolean;
};

export const InputPhone = (props: InputFieldProps) => {
  const { registration, error, value, floating = false, label } = props;
  const { control, setValue } = useForm();
  const { onChange: originalFormChange, name } = registration;

  useEffect(() => {
    if (value && name) {
      setValue(name, value);
    }
  }, [value]);

  const parsePhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) {
      return {
        countryCode: undefined,
        number: '',
      };
    }

    const spaceIndex = phoneNumber.indexOf(' ');
    if (spaceIndex !== -1) {
      return {
        countryCode: phoneNumber.substring(0, spaceIndex),
        number: phoneNumber.substring(spaceIndex + 1),
      };
    }
    return {
      countryCode: undefined,
      number: phoneNumber,
    };
  };

  const handleChange = (change: (event: string) => void) => (newValue: string) => {
    const { countryCode, number } = parsePhoneNumber(newValue);
    const e = {
      target: {
        value: number,
        name,
      },
    };
    originalFormChange?.(e);
    change(newValue);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Controller
        name={name ?? ''}
        control={control}
        render={({ field: { onChange, value } }) => {
          const { countryCode, number } = parsePhoneNumber(value || '');

          return (
            <PhoneInput
              className={clsx('form-control phone-control', floating && 'floating')}
              placeholder="Enter phone number"
              value={number}
              onChange={handleChange(onChange)}
              defaultCountry={countryCode || undefined}
              inputProps={{
                readOnly: true,
              }}
            />
          );
        }}
      />
    </FieldWrapper>
  );
};

import { LoginValues } from '@/types';
import { axios } from '../../../lib/axios';

export type UploadImageDTO = {
    profilePicture: File;
  };

export const getUpdatedProfile = (id: string | null, data : LoginValues) => {
    return axios.post(`/users/update/${id}`,data)
}

// export const uploadPic = (formData: FormData) => {
//     return axios.post(`/users/upload`,formData)
// }

export const uploadImage = (data: UploadImageDTO, id: string): Promise<void> => {
    const form = new FormData();
    const extension = data.profilePicture.name.split('.').at(-1);
    const name = new Date().getTime() + 'image.' + extension;
    form.append('profile_picture', data.profilePicture, name);
    return axios.post('/users/upload/'+id, form);
  };
  

import { useQuery } from 'react-query';

import { axiosMain } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ContractDetail } from '@/types';

export const getContracts = async (id: string): Promise<ContractDetail[]> => {
  return await axiosMain.get(`/contracts?userId=${id}`);
};

type QueryFnType = typeof getContracts;

type useContractList = {
  config?: QueryConfig<QueryFnType>;
  id: string;
};

export const useContracts = ({ config, id }: useContractList = { id: '' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [`AllContracts-${id}`],
    queryFn: () => getContracts(id),
  });
};

import { useNavigate } from 'react-router-dom';
import forgetImage from '@/assets/forget.svg';
import { Button, Link } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { Layout } from '../../components/Layout';
import { ForgetPasswordForm } from '../../components/ForgetPasswordForm';


export const ForgetPassword = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <Layout title="Forget Password">
      <ForgetPasswordForm/>
    </Layout>
  );
};
